import { dnetwork, TObject } from "corexxx";
import { useState } from "react";
import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DButton } from "@libs/dweb/DButton";
import { DCodeDisplay } from "@libs/dweb/DCodeDisplay";
import { DCol } from "@libs/dweb/DCol";
import { DRow } from "@libs/dweb/DRow";
import { DSearchButton } from "@libs/dweb/DSearchButton";
import { DSelectInput } from "@libs/dweb/DSelectInput";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";
import { DHook } from "@libs/common_hooks/DHook";

export function RemoteShell() {
  let admin = useAdmin();
  let app = useDAppCommand();
  let [output, setOutput] = useState<TObject>({});
  let [preset, setPreset] = useState<string>("git_pull");
  let [all_preset, set_all_preset] = useState([]);
  DHook.useAsyncEffect(async () => {
    let data = await app.api.get("/api/utils/bash/get_all_presets");
    set_all_preset(data.out.map((x: any) => ({ text: x, value: x })));
  }, []);

  return (
    <DBasePage>
      <DCol>
        <DRow style={{ flex: 0 }}>
          <DSearchButton
            onSearch={async (text) => {
              let data = await app.api.getSimpleStore(
                app.state.simpleStoreEndpoint + "/api/utils/bash?cmd=" + text,
              );
              setOutput(data.out);
            }}
            style={{ flex: 1, marginRight: 40 }}
            btn_text="Run"
          />
          <DSelectInput
            value={preset}
            onChange={setPreset}
            options={all_preset}
          />
          <DButton
            style={{ borderRadius: 0 }}
            text="Run Preset"
            onClick={async () => {
              let data = await app.api.getSimpleStore(
                app.state.simpleStoreEndpoint +
                "/api/utils/bash?preset=" +
                preset,
              );
              setOutput(data.out);
            }}
          ></DButton>
        </DRow>

        <DCodeDisplay
          code={
            (output?.stdout || "stdout will be shown here") +
            "\n=====\n" +
            (output?.stderr || "stderr will be shown here")
          }
          style={{ marginTop: 20 }}
        />
      </DCol>
    </DBasePage>
  );
}
