import { HelpOutline } from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { IconButton, Typography } from "@mui/material";
import { dcolors } from "corexxx";
import { EmailIcon, FacebookIcon } from "react-share";
import { buildConfig } from "../../version";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
export const DFooter = () => {
  let appCommand = useDAppCommand();
  return (
    <DCol
      style={{
        padding: 10,
        flex: 0,
        alignItems: "center",
        background: "#0000000010",
      }}
    >
      <DRow style={{ justifyContent: "center", alignItems: "center" }}>
        {appCommand.getAppConfig()?.social?.email ? (
          <IconButton
            href={appCommand.getAppConfig()!!.social!!.email!!}
            target="_blank"
          >
            <EmailIcon style={{ margin: 10 }} />
          </IconButton>
        ) : null}

        {appCommand.getAppConfig()?.social?.whatsapp ? (
          <IconButton
            href={appCommand.getAppConfig()!!.social!!.whatsapp!!}
            target="_blank"
          >
            <WhatsAppIcon style={{ margin: 10 }} />
          </IconButton>
        ) : null}
        {appCommand.getAppConfig()?.social?.fb_page ? (
          <IconButton
            href={appCommand.getAppConfig()!!.social!!.fb_page!!}
            target="_blank"
          >
            <FacebookIcon style={{ margin: 10 }} />
          </IconButton>
        ) : null}
        {appCommand.getAppConfig()?.social?.linkedin ? (
          <IconButton
            href={appCommand.getAppConfig()!!.social!!.linkedin!!}
            target="_blank"
          >
            <LinkedInIcon style={{ margin: 10 }} />
          </IconButton>
        ) : null}
        {appCommand.getAppConfig()?.social?.release_note ? (
          <IconButton
            href={appCommand.getAppConfig()!!.social!!.release_note!!}
            target="_blank"
          >
            <HelpOutline style={{ margin: 10 }} />
          </IconButton>
        ) : null}
      </DRow>
      <DRow style={{ flex: 0, alignSelf: "center" }}>
        <Typography
          style={{
            color: dcolors.pallets.grey800,
            textAlign: "center",
            fontSize: 12,
          }}
        >
          © copyright grodok.com 2023 | Last build:{buildConfig.date}
        </Typography>
      </DRow>
    </DCol>
  );
};
