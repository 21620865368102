// Let's create a more concise implementation with a parent component (DAlternativeScreenHolder) that will handle
// switching between screens based on the activescreen prop,
// and child components (DAlternativeScreen) that represent each screen

import React, { CSSProperties } from "react";
import { DCol } from "./DCol";

export function DAlternativeScreenHolder({
  activescreen,
  children,
  style
}: {
  activescreen: string;
  children?: any;
  style?: CSSProperties
}) {
  const activeScreen = React.Children.toArray(children).find(
    (child: any) => child.props.name === activescreen,
  );
  return <DCol style={style}>{activeScreen}</DCol>;
}

export function DAlternativeScreen({
  children,
  name,
}: {
  children: any;
  name: string;
}) {
  return <>{children}</>;
}
