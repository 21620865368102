import { DefaultWebRtcCallScreen } from "@libs/components/screens/DefaultWebRtcCallScreen";
import { DPageRootLayoutWithDrawer } from "@libs/dweb/DPageRootLayout";
import { DText } from "@libs/dweb/DText";
import { DWebsocketConsole } from "@libs/dweb/DWebsocketConsole";
import { dcolors } from "corexxx";
import { useAdmin } from "../Hook";
import { AdminToolsPage } from "./AdminToolsPage";
import { ApiExplorerPage } from "./APIExplorer";
import { AppInsightsPage } from "./AppInsightsPage";
import { AssetsPage } from "./AssetsPage";
import { CrashLogPage } from "./CrashLogPage";
import { CronJobs } from "./CronJobs";
import { HealthPage } from "./HealthPage";
import { LiveLogPage } from "./LiveLog";
import { ReliabilityMatrix } from "./ReliabilityMatrix";
import { RemoteShell } from "./RemoteShell";
import { SecureKeyPage } from "./SecureKey";
import { SevManager } from "./SevManager";

export function DashBoard() {
  let adminHook = useAdmin();
  return (
    <DPageRootLayoutWithDrawer
      bodyStyle={{ backgroundColor: dcolors.pallets.yellow50 }}
      config={{
        title: "",
      }}
      drawerOpen={true}
      drawerPreset={{
        drawerWidth: 300,
        headerText: "SimpleStore Node Overview",
        footerText: "DSoftware @2022",
        footerExtra: (
          <div>
            <DText style={{ fontSize: 12 }}>
              Version : {adminHook.state.info?.version || "0"}.0 R date:{" "}
              {adminHook.state.info?.release_date || ""}
            </DText>
          </div>
        ),
        drawerBackground: "#e7e7e7",
        color: "black",
        headerExtra: <div style={{ width: "100%" }}></div>,
        menuList: [
          {
            name: "Health Page",
            screen: <HealthPage />,
          },
          {
            name: "API Explorer",
            screen: <ApiExplorerPage />,
          },
          {
            name: "Crash Overviews",
            screen: <CrashLogPage />,
          },
          {
            name: "Relibility Matix",
            screen: <ReliabilityMatrix />,
          },
          {
            name: "Sev Manager",
            screen: <SevManager />,
          },
          {
            name: "Live Log",
            screen: <LiveLogPage />,
          },
          {
            name: "Cron Jobs",
            screen: <CronJobs />,
          },
          {
            name: "Remote Shell",
            screen: <RemoteShell />,
          },
          {
            name: "Websocket Console",
            screen: <DWebsocketConsole />,
          },
          {
            name: "SecureKeys",
            screen: <SecureKeyPage />,
          },
          {
            name: "AdminTools",
            screen: <AdminToolsPage />,
          },
          {
            name: "RTC Call",
            screen: <DefaultWebRtcCallScreen />,
          },
          {
            name: "Assets Manager",
            screen: <AssetsPage />,
          },
          {
            name: "App Insights",
            screen: <AppInsightsPage />,
          },
        ],
      }}
    ></DPageRootLayoutWithDrawer>
  );
}
