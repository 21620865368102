// @ts-nocheck
import { CheckCircleOutline } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from "@mui/material";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { TObject, dcolors, dnetwork } from "corexxx";
import React, { CSSProperties, useState } from "react";
import { DWebTS } from "../common_ts/DWebTS";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";
import { DTextLines } from "./DTextLines";

export type TPaymentConfig = {
  public_key?: string;
  subscription_name: string;
  title?: string;
  direct_url: string;
  amount: number;
};

export const StripePaymentDialog = ({
  style,
  config,
}: {
  style?: CSSProperties;
  config: TPaymentConfig;
}) => {
  const [open, setOpen] = React.useState(false);

  const PUBLIC_KEY =
    config?.public_key ||
    "pk_test_51LvQTTCtA5cdHPVpCd5yFPSzSSK1NI7gaeqKV7worTKQXPbjUNhVssedYXlJ93sO1T4qMAWZp5BSaSn08lJJb27e00WAdponhq";
  const stripeTestPromise = loadStripe(PUBLIC_KEY);
  const handleClickOpen = () => {
    DWebTS.danalytics.report_action("subscription_btn_clicked");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <DCol style={style}>
      <Button variant="outlined" onClick={handleClickOpen}>
        Get a membership
      </Button>
      <Dialog open={open} keepMounted onClose={handleClose}>
        {config?.title ? (
          <DialogTitle>
            {config?.title || "Thanks for considering subscription"}
          </DialogTitle>
        ) : null}
        <DialogContent>
          <Elements stripe={stripeTestPromise}>
            <PaymentForm config={config} />
          </Elements>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DCol>
  );
};

export function PaymentForm({ config }: { config: TPaymentConfig }) {
  const [output, setOutput] = useState<
    { status: "success" | "error"; msg: string } | undefined
  >(undefined);
  const [result, setResult] = useState<TObject | undefined>(undefined);
  const appCommand = useDAppCommand();
  const stripe = useStripe();
  const elements = useElements();
  const [progress, setProgress] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [amount, setAmount] = useState(config?.amount || 5);
  const [couponResult, setCouponResult] = useState("");
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {},
      invalid: {},
    },
  };

  const applyCoupon = () => {
    switch (coupon) {
      case "":
        setAmount(config.amount);
        setCouponResult("");
        break;
      case "TEST35":
        setAmount(0.35);
        setCouponResult("Coupon applied to have only .35 GBP");
        break;
      case "FAANG80":
        setAmount(config.amount * 0.2);
        setCouponResult("Coupon applied for 80% off");
        break;
      default:
        setCouponResult("invalid coupon");
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setProgress(true);
      let card = elements!!.getElement(CardElement);

      if (!stripe || !elements || !card) {
        throw "some error";
      }
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: card,
      });
      if (error) {
        throw error;
      }
      const { id } = paymentMethod;
      const resp = await dnetwork.postSimpleStore(
        appCommand.state.simpleStoreEndpoint +
          `/api/simple_auth/add_subscription_by_stipe`,
        {
          payment_method_id: id,
          subscription_name: config?.subscription_name || "test",
          auth_token_: appCommand.accountState?.auth_token_,
          override_amount: amount * 100,
        },
      );
      resp.out = DWebTS.decode(resp.out);
      setResult(resp);
      setOutput({ status: "success", msg: "Thanks for teh subscription" });
      appCommand.reloadUser();
    } catch (error: any) {
      DWebTS.danalytics.report_error(new Error(error.message));
      setOutput({ status: "error", msg: error.message });
    }
    setProgress(false);
  };
  if (output && output.status == "success" && result) {
    return (
      <DCol
        style={{
          justifyItems: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <CheckCircleOutline
          style={{
            color: dcolors.pallets.green500,
            fontSize: 100,
            marginBottom: 14,
          }}
        />
        <DText
          style={{ textAlign: "center", textTransform: "capitalize" }}
          mode="title"
        >
          {result?.msg || ""}
        </DText>
        <DText style={{ textAlign: "center" }}>
          {result?.out?.description}
        </DText>
        <Button
          variant="contained"
          style={{ marginTop: 40 }}
          href={result?.out.recept_url}
        >
          Download recept
        </Button>
      </DCol>
    );
  }
  return (
    <DCol style={{ marginTop: 30, marginBottom: 30 }}>
      <DText mode="title" style={{ marginBottom: 30, textAlign: "center" }}>
        Payment subscription
      </DText>
      <DTextLines
        style={{ marginBottom: 30 }}
        lines={[
          `Thanks for considering paying £${amount} GBP to help the service running.`,
          "You will be getting acccess to tools for 365 days",
          "If you are not happy with the product, request a return in 7 days",
        ]}
      />

      {output?.status == "error" ? (
        <Alert severity="error" style={{ marginBottom: 20 }}>
          {output.msg}
        </Alert>
      ) : null}
      <form onSubmit={handleSubmit}>
        <DCol style={{ background: dcolors.pallets.yellow100, padding: 20 }}>
          <DText style={{ marginBottom: 18 }}>Your card details:</DText>
          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: CARD_OPTIONS.style,
                  iconStyle: "solid",
                }}
              />
            </div>
          </fieldset>
          <button
            disabled={progress}
            style={{
              marginTop: 20,
              backgroundColor: dcolors.pallets.blue600,
              border: 0,
              height: 40,
              color: "white",
              cursor: "pointer",
            }}
          >
            {progress ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Pay £" + amount
            )}
          </button>
          <DHtml
            style={{ marginTop: 20, fontSize: 14 }}
            html={
              config?.direct_url
                ? `<p><span> Your payment is secured by Stripe payment gateway. We don't store your card details and not used by our system.</span><span>If you want to pay in Stripe website, you can do it <a href="${config?.direct_url}">here</a>, but it will take few hours to enable the subscription.</span>`
                : "<p><span> Your payment is secured by Stripe payment gateway. We don't store your card details and not used by our system.</span>" +
                  "</p>"
            }
          />
          <DRow style={{ marginTop: 30, justifyItems: "center" }}>
            <DText style={{ flex: 1, fontSize: 13 }}>
              Do you have a coupon ?
            </DText>
            <DCol>
              <Input
                placeholder="Enter coupon"
                size="small"
                endAdornment={<Button onClick={applyCoupon}>Apply</Button>}
                onChange={(e) => setCoupon(e.target.value)}
                value={coupon}
              />
              {couponResult.length > 0 ? (
                <DText mode="footnote">{couponResult}</DText>
              ) : null}
            </DCol>
          </DRow>
        </DCol>
      </form>
    </DCol>
  );
}
