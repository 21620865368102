import { Typography } from "@mui/material";
import { dcolors } from "corexxx";
import { CSSProperties } from "react";
export const DHtml = ({
  html,
  style,
}: {
  html: string;
  style?: CSSProperties;
}) => {
  return (
    <Typography style={{ color: dcolors.pallets.grey800, ...style }}>
      <span dangerouslySetInnerHTML={{ __html: html }}></span>
    </Typography>
  );
};
