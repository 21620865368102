import { TObject } from "corexxx";
import { DCol } from "./DCol";
import { DText } from "./DText";

// Show object as a table like a key vlaue pair
export const DTextJSON = ({ obj }: { obj: TObject }) => {
  return (
    <DCol style={{ padding: 10, border: '1x solid rgb(0, 0, 0)' }}>
      <DText style={{ fontFamily: "monospace", whiteSpace: "pre", fontSize: 10 }}>{JSON.stringify(obj, null, '    ')}</DText>
    </DCol>
  );
};
