import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { DRow } from "./DRow";
import { DText } from "./DText";

export const DPageHeader = ({
  title,
  rightView,
  style,
}: {
  title: string;
  rightView?: JSX.Element;
  style?: CSSProperties;
}) => {
  return (
    <DRow style={{ marginTop: 30, marginBottom: 20, flex: 0, ...style }}>
      <DText
        style={{
          fontSize: 25,
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: 1,
          color: dcolors.pallets.grey800,
          flex: 1,
        }}
      >
        {title}
      </DText>
      <DRow
        style={{ alignSelf: "flex-end", alignItems: "flex-end", flex: "none" }}
      >
        {rightView}
      </DRow>
    </DRow>
  );
};
