import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { dcolors } from "corexxx";
import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DText } from "./DText";
export const DTextLines = ({
  lines,
  style,
}: {
  lines: string[];
  style?: CSSProperties;
}) => {
  return (
    <DCol style={style}>
      {lines.map((x, idx) => (
        <DText
          style={{
            display: "flex",
            verticalAlign: "middle",
            textAlign: "left",
            marginBottom: 10,
          }}
          key={idx}
        >
          <CheckCircleOutlineIcon
            style={{ color: dcolors.pallets.green500, marginRight: 8 }}
          />
          {x}
        </DText>
      ))}
    </DCol>
  );
};
