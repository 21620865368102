import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Chip, Tab } from "@mui/material";
import React, { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";

export const DTabView = ({
  data,
  style,
  config,
  extraRightView,
}: {
  config: { tabview: "tab" | "chip" };
  data: { text: string; view?: JSX.Element }[];
  style?: CSSProperties;
  extraRightView?: JSX.Element;
}) => {
  const [value, setValue] = React.useState("0");
  data = data || [];
  return (
    <TabContext value={value}>
      <DCol style={style}>
        <DRow style={{ flex: 1, width: "100%" }}>
          {config?.tabview == "tab" ? (
            <TabList
              value={value}
              onChange={(i: any, v: string) => setValue(v)}
              indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              {data.map((x, idx) => (
                <Tab label={x.text} value={idx + ""} />
              ))}
            </TabList>
          ) : null}
          {config?.tabview == "chip" ? data.map((x, idx) => (
            <Chip
              label={x.text}
              onClick={() => {
                setValue(idx + "");
              }}
              style={{ margin: 4, marginRight: 10 }}
              color={idx + "" == value ? "success" : "default"}
            />
          )) : null}
          <DSpace />
          {extraRightView}
        </DRow>
        {data.map((x, idx) => (
          <TabPanel
            value={idx + ""}
            style={{ margin: 0, padding: 0, display: "flex" }}
          >
            {x.view}
          </TabPanel>
        ))}
      </DCol>
    </TabContext>
  );
};
