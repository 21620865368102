import { TextField } from "@mui/material";
import { TStringCallback } from "corexxx";
import { CSSProperties } from "react";

export function DInputString({
  label,
  value,
  onChange,
  style,
}: {
  label: string;
  value: string;
  onChange: TStringCallback;
  style?: CSSProperties;
}) {
  return (
    <TextField
      size="small"
      style={{ width: 100, flex: 'none', ...style }}
      placeholder={label}
      label={label}
      variant="outlined"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
