import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DButton } from "@libs/dweb/DButton";
import { DInputString } from "@libs/dweb/DInputString";
import { DRow } from "@libs/dweb/DRow";
import { DTextObject } from "@libs/dweb/DTextObject";
import { dnetwork, TObject } from "corexxx";
import React, { useState } from "react";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function SecureKeyPage() {
  const [info, setInfo] = React.useState<TObject>({});
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();
  const [key, setkey] = useState("");
  const [value, setValue] = useState("");

  React.useEffect(() => {
    appCommand.api.showPrompt("Enter Admin passcode", async (code) => {
      setInfo(
        await dnetwork.getJson(
          `${appCommand.state.simpleStoreEndpoint}/info?admin=${code}`,
        ),
      );
    });
  }, []);

  return (
    <DBasePage>
      <DTextObject obj={info?.secureKeys || {}} />
      <DRow>
        <DInputString label="Key" value={key} onChange={setkey} />
        <DInputString label="Value" value={value} onChange={setValue} />
        <DButton
          onClick={() => {
            appCommand.api.safeCall(async () => {
              let payload: TObject = { db_name: "SECURE_KEYS" };
              payload[key] = value;
              await dnetwork.postSimpleStore(
                `${appCommand.state.simpleStoreEndpoint}/api/utils/system_db/update`,
                payload,
              );
              alert(
                "Updated!. new config applied on run time - non need to restart the server.",
              );
            });
          }}
          text="Submit"
        ></DButton>
      </DRow>
    </DBasePage>
  );
}
