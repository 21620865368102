import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";

// Making two column Layouts
export function DLayoutTwoCol({
  elements,
  style,
}: {
  elements: JSX.Element[][];
  style?: CSSProperties;
}) {
  return (
    <DCol style={style}>
      {elements.map((e, idx) => {
        return (
          <DRow
            key={idx}
            style={{ justifyItems: "center", paddingTop: 5, paddingBottom: 5 }}
          >
            <DRow style={{ flex: 1 }}>{e[0]}</DRow>
            <div>{e[1]}</div>
          </DRow>
        );
      })}
    </DCol>
  );
}
