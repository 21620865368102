import { AlertColor } from "@mui/material";
import { TObject, TStringCallback, dassert, dlog, dnetwork } from "corexxx";
import { FirebaseOptions } from "firebase/app";
import { useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSpeechSynthesis } from "react-speech-kit";
import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import _ from "underscore";
import { DWebTS } from "../common_ts/DWebTS";

/******************************************************
 * App Commands and Hooks
 *
 *********************************************************/

export type TSafeCallConfig = {
  silentNotification?: boolean,
  silentLoading?: boolean
};

export type TBasicInfo = {
  icon?: any;
  title?: string;
  subtitle?: string;
  extra?: any;
};
export type TSeoConfig = {
  page_title?: string;
  page_description?: string;
  keywords?: string;
  img?: string;
};
export type TAppConfig = {
  app_id: string;
  app_name: string;
  primary_background?: string;// this allows gradient color to put any background like accont page
  primary_color: string; // must ahve a primary color
  dark?: boolean;
  logo?: any;
  subscription_key?: string; // we define one subscription only
  google_analytics_tag?: string;
  stripe_public_key?: string;
  seo?: TSeoConfig;
  google_client_id?: string; // this is mainly for google signin
  firebase_config?: FirebaseOptions;
  simpleStoreEndpoint?: string; // allow to use SimpleStore Enpoint on boot
  //social
  social?: {
    release_note?: string;
    email?: string;
    fb_page?: string;
    linkedin?: string;
    whatsapp?: string;
    twitter?: string;
  };
  // More app info
  app_slogan?: string;
  app_subtext?: string;
};

export type TNotification = {
  type: AlertColor;
  msg: string;
  is_loading?: boolean;
};
export type TSubscription = {
  subscription_key: string;
  status: string;
  expiry_date: string;
  recept_url: string;
};
export type TAccountInfo = {
  _id: string;
  username: string;
  auth_token_: string;
  email: string;
  image: string;
  name: string;
  subscription_list?: TSubscription[];
  permission_group?: string[];
  [key: string]: any;
  currency?: DWebTS.TCurrency;
};

export const notificationState = atom<TNotification | undefined>({
  key: "notification", // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
});

// allow global show and hide privacy text like money
export const textPrivacyStateAtom = atom<boolean>({
  key: "textPrivacyState", // unique ID (with respect to other atoms/selectors)
  default: false, // default value (aka initial value) --> default will hind
});

export const InformationDialogStateAtom = atom<
  { title: string; body: string } | undefined
>({
  key: "InformationDialogStateAtom", // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
});

const { persistAtom } = recoilPersist();
const accountStateAtom = atom<TAccountInfo | undefined>({
  key: "account_state",
  default: undefined,
  effects_UNSTABLE: [persistAtom],
});
export const loadingStateAtom = atom<boolean>({
  key: "loading_state",
  default: false,
});
const gkStateAtom = atom<TObject>({
  key: "gk_state",
  default: {},
  // effects_UNSTABLE: [persistAtom],
});
export const globalSettingVisibilityAtom = atom<boolean>({
  key: "globalSettingVisibility",
  default: false,
});
export const simpleStoreEndpointAtom = atom<string>({
  key: "simpleStoreEndpoint",
  default: "https://simplestore.dipankar.co.in",
  effects_UNSTABLE: [persistAtom],
});

let _appConfig: TAppConfig | undefined = undefined;
export function setAppConfig(config: TAppConfig) {
  _appConfig = config;
}

export type TRequestOverride = {
  allowAnonymous?: boolean;
};

export const useDAppCommand = () => {
  const [_notification, set_notification] = useRecoilState<
    TNotification | undefined
  >(notificationState);
  const [informationDialog, setInformationDialog] = useRecoilState(
    InformationDialogStateAtom,
  );
  const [accountState, setAccountState] = useRecoilState(accountStateAtom);
  const [textPrivacyState, setTextPrivacyState] =
    useRecoilState(textPrivacyStateAtom);
  const [_loadingState, setLoadingState] = useRecoilState(loadingStateAtom);
  const [gkState, setGkState] = useRecoilState(gkStateAtom);
  const [globalSettingVisibility, setGlobalSettingVisibility] = useRecoilState(
    globalSettingVisibilityAtom,
  );
  const [simpleStoreEndpoint, set_simpleStoreEndpoint] = useRecoilState(
    simpleStoreEndpointAtom,
  );
  const { speak } = useSpeechSynthesis();
  var navigtaion: NavigateFunction | null = null;
  try {
    navigtaion = useNavigate();
  } catch (e) { }

  // reload the user - This was needed when suscription changed
  const reloadUser = useCallback(async () => {
    if (!accountState) {
      dlog.d("not able to load as user not login");
    }
    let account = await dnetwork.getSimpleStore(
      simpleStoreEndpoint +
      `/api/simple_auth/get_info?auth_token_=${accountState?.auth_token_}`,
    );
    const ds = DWebTS.decode(account.out);
    setAccountState(ds);
  }, [accountState]);

  function isDebugMode() {
    if (gkState["gk_make_prod"]) {
      return false;
    }
    return gkState["gk_debugging"] || process.env.NODE_ENV === "development";
  }

  async function _safeCall(
    func: any,
    success_msg?: string,
    error_msg?: string,
    config?: TSafeCallConfig
  ) {
    if (!config?.silentNotification) {
      set_notification({ type: "info", msg: "Loading..", is_loading: true });
    }
    if (!config?.silentLoading) {
      setLoadingState(true)
    }
    try {
      let res = await func();
      if (!config?.silentNotification) {
        set_notification({
          type: "success",
          msg: res?.msg || success_msg || "Network call succeed",
        });
      }
      if (!config?.silentLoading) {
        setLoadingState(false);
      }
      return res;
    } catch (e: any) {
      if (!config?.silentNotification) {
        set_notification({
          type: "error",
          msg: (error_msg || "Some error:") + e.message,
        });
      }
    }
    if (!config?.silentLoading) {
      setLoadingState(false);
    }
  }

  const _showPrompt = async function (msg: string, func: TStringCallback) {
    var userInput = prompt(msg || "Please enter your name:");
    if (userInput !== null) {
      await func(userInput);
    } else {
      alert("Prompt was canceled.");
    }
  };

  const _openNewTab = function (url: string) {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const _logout = function () {
    setAccountState(undefined);
  };
  const _checkLogin = async function () {
    try {
      let data = await dnetwork.postSimpleStore(
        simpleStoreEndpoint + "/api/simple_auth/validate_login_session",
        {
          username: accountState?.username,
          auth_token_: accountState?.auth_token_,
        },
      );
      return true;
    } catch (e) {
      return false;
    }
  };

  const _postSimpleStore = async (
    url: string,
    data: TObject,
    config?: TRequestOverride,
    config2?: { silentNotification?: boolean, silentLoading?: boolean },
  ) => {
    return await _safeCall(
      async () => {
        return await dnetwork.postSimpleStore(_getUrl(url), {
          auth_token_:
            accountState?.auth_token_ ||
            (config?.allowAnonymous ? "anonymous" : null),
          ...data,
        });
      },
      undefined,
      undefined,
      config2,
    );
  };
  const _getUrl = (url: string) => {
    return (url[0] == "/") ? simpleStoreEndpoint + url : url;
  }

  const _getSimpleStore = async (
    url: string,
    config?: TRequestOverride,
    config2?: TSafeCallConfig,
  ) => {
    // this is anyway a post to pass the auth_token_
    return await _safeCall(
      async () => {
        return await dnetwork.postSimpleStore(_getUrl(url), {
          auth_token_:
            accountState?.auth_token_ ||
            (config?.allowAnonymous ? "anonymous" : null),
        });
      },
      undefined,
      undefined,
      config2,
    );
  }

  const _showInformationDialog = (title: string, body: string) => {
    setInformationDialog({ title: title, body: body });
  };

  const _speak = (text: string) => {
    speak({ text: text });
  };
  const _init = async (appConfig: TAppConfig) => {
    dlog.d("[ONCE] useDAppCommand inited started");
    _appConfig = appConfig;
    DWebTS.initAppTs(appConfig);
    if (appConfig.simpleStoreEndpoint) {
      set_simpleStoreEndpoint(appConfig.simpleStoreEndpoint);
    }
    // always set privacy enabled by default
    setTextPrivacyState(true);
    dlog.d("useDAppCommand inited ended");
  };

  const _setNotification = (noti: TNotification) => {
    set_notification(noti);
  };

  const _getSubscription = useCallback((): TSubscription | null => {
    if (
      !accountState ||
      !accountState.subscription_list ||
      !_.isArray(accountState.subscription_list)
    ) {
      return null;
    }
    let data = accountState.subscription_list?.filter((y) => {
      if (y.subscription_key == _appConfig?.subscription_key) {
        if (new Date(y.expiry_date) > new Date()) {
          return true;
        }
      }
      return false;
    });
    if (data.length == 0) {
      return null;
    }
    return data[0];
  }, [accountState]);

  return {
    init: _init,
    getAppConfig: () => {
      return _appConfig;
    },
    setNotification: _setNotification,
    accountState: accountState,
    isLoggedIn: accountState?._id != undefined,
    setUserLogin: (info: TAccountInfo) => {
      setAccountState(info);
    },

    reloadUser: reloadUser,
    updateAccount: async (data: TObject) => {
      dassert.verifyNotNullAndUndef(accountState, "missing account state");
      await dnetwork.postSimpleStore(
        simpleStoreEndpoint + "/api/simple_auth/update_info",
        { auth_token_: accountState!!.auth_token_, ...data },
      );
      await reloadUser();
    },

    logout: async () => {
      try {
        let data = await dnetwork.postSimpleStore(
          simpleStoreEndpoint + "/api/simple_auth/logout",
          {
            auth_token_: accountState!!.auth_token_,
            username: accountState?.username,
          },
        );
      } catch (e) { }
      setAccountState(undefined);
    },

    loading: (loading: boolean) => {
      setLoadingState(loading);
    },
    // subscriptions
    getSubscription: _getSubscription,
    // Gk states
    gkState: gkState,
    setGkState: (str: TObject) => {
      setGkState({ ...gkState, ...str });
    },
    // use http://localhost:3000/account?gk_debugging=1 for setting the GK On
    isDebugMode: () => {
      // test as production
      // example force to make as prod http://localhost:3000/dashboard?gk_make_prod=true
      return isDebugMode();
    },
    isFishFooding: () => {
      return gkState["gk_fishfooding"] == "1" || isDebugMode();
    },
    isDogfooding: () => {
      return gkState["gk_dogfooding"] == "1";
    },
    isGkEnabled: (str: string) => {
      return gkState[str] != null && gkState[str] != undefined;
    },
    fireAndForget: async (
      func: () => void,
      config?: { success_msg?: string; error_msg?: string; rethrow?: boolean },
    ): Promise<any> => {
      try {
        let result = await func();
        set_notification({ type: "success", msg: "Success!" });
        return result;
      } catch (e: any) {
        set_notification({ type: "error", msg: "Error! " + e.message });
        if (config?.rethrow != false) {
          throw Error("Error Happened. " + e.message);
        }
      }
    },

    // == Network helper functions ==
    authCookies: { auth_token_: accountState?.auth_token_ },

    state: {
      informationDialog,
      // TODO: Have exopairy dates as well
      has_subscription:
        accountState?.subscription_list?.filter(
          (x) => x.subscription_key == _appConfig?.app_id,
        ).length == 1,
      is_logged_in: !accountState?._id,
      is_loading: _loadingState,
      notification: _notification,
      globalSettingVisibility: globalSettingVisibility,
      simpleStoreEndpoint: simpleStoreEndpoint,
      appConfig: _appConfig,
      accountState: accountState,
      auth_token_: accountState?.auth_token_,
      textPrivacyState: textPrivacyState,
    },
    api: {
      speak: _speak,
      setTextPrivacyState: setTextPrivacyState,
      showInformationDialog: _showInformationDialog, // this will show a global information dialog
      hideInformationDialog: () => setInformationDialog(undefined),
      // login apu.
      logout: _logout,
      checkLogin: _checkLogin,
      openNewTab: _openNewTab,
      go_back: () => {
        if (!navigtaion) {
          throw "Navigation is not allowed here";
        }
        navigtaion(-1);
      },

      navigate: (target: string, data?: TObject) => {
        /*
                 In the target view use the code to fetch the data
                     const location = useLocation();
                    const item: TObject = location.state;
                */
        if (!navigtaion) {
          throw "Navigation is not allowed here";
        }
        navigtaion(target, { state: data });
      },
      setLoading: (loading: boolean) => {
        setLoadingState(loading);
      },
      setNotification: (noti: TNotification) => {
        set_notification(noti);
      },
      // url might not conatins domain
      get: async (url: string, config?: TRequestOverride) => {
        if (url[0] == "/") {
          url = simpleStoreEndpoint + url;
        }
        return await _safeCall(async () => await dnetwork.get(url));
      },
      post: async (url: string, data: TObject, config?: TRequestOverride) => {
        if (url[0] == "/") {
          url = simpleStoreEndpoint + url;
        }
        return await _safeCall(async () => await dnetwork.post(url, data));
      },
      safeCall: _safeCall,
      setGlobalSettingVisibility: setGlobalSettingVisibility,
      setSimpleStoreEndpoint: set_simpleStoreEndpoint,
      postSimpleStore: _postSimpleStore,
      getSimpleStore: _getSimpleStore,

      // JS native function
      showPrompt: _showPrompt,
    },
  };
};
