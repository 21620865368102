import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DSplitDataView } from "@libs/dweb/DSplitDataView";
import { DBasePage } from "../App";

export function CrashLogPage() {
  var appCommand = useDAppCommand();
  return (
    <DBasePage>
      <DSplitDataView
        url={appCommand.state.simpleStoreEndpoint + "/api/crash_log"}
        columns={[
          {
            field: "app_name",
            headerName: "App Name",
          },
          {
            field: "message",
            headerName: "Error Title",
          },
          {
            field: "total_count",
            headerName: "Hits",
          },
          {
            field: "last_reported",
            headerName: "Last Reported",
            view_type: "time",
          },
        ]}
        getDetailsText={(obj) => `<pre>${obj["first_line"]}</pre>`}
        buildSearchParams={(x) => ({ message: "regex:" + x })}
      />
    </DBasePage>
  );
}
