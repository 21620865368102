import { dlog } from "corexxx";
import { DWebTS } from "../../common_ts/DWebTS";
import { DBox } from "../../dweb/DBox";
import { RichTextEditor } from "../../dweb/DCodeCompiler";
import { DForm } from "../../dweb/DForm";
import { DResponsiveWindow } from "../../dweb/DResponsiveWindow";
import { DSplitButton } from "../../dweb/DSplitButton";
import { DText } from "../../dweb/DText";

export const ComponentTestScreen = () => {
  return (
    <DResponsiveWindow>
      <DText>This page for testing the components</DText>
      <RichTextEditor
        onChange={(a) => {}}
        style={{}}
        onSave={(a) => {
          dlog.d(a);
        }}
        initValue="<p><b>TEST</b></p>"
      />
      <DText>This page for testing the components</DText>
      <DBox style={{ flex: 0 }}>
        <DForm
          model={{
            data: [
              {
                type: "files",
                name: "files",
                required: true,
              },
            ],
          }}
          onSubmit={async (obj) => {
            console.log(
              await DWebTS.uploadFiles(obj.files, { user_id: "test" }),
            );
          }}
          config={{
            title: "hello",
            trigger_btn_text: "Submit",
            clearOnSubmit: true,
          }}
        />
      </DBox>
      <DBox>
        <DSplitButton
          actions={[
            { text: "One", onClick: () => {} },
            { text: "One", onClick: () => {} },
            { text: "One", onClick: () => {} },
            { text: "One", onClick: () => {} },
          ]}
        />
      </DBox>
    </DResponsiveWindow>
  );
};
