import { DHook } from "@libs/common_hooks/DHook";
import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DCodeDisplay } from "@libs/dweb/DCodeDisplay";
import { DSplitButton } from "@libs/dweb/DSplitButton";
import React from "react";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function LiveLogPage() {
  const [code, setCode] = React.useState("");
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();
  const load = async () => {
    let data = await appCommand.api.getSimpleStore("/api/utils/admin_tools/logs")
    setCode(data.out.stdout)
  }
  DHook.useAsyncEffect(async () => {
    load()
  }, [appCommand.state.simpleStoreEndpoint]);

  return (
    <DBasePage>
      <DSplitButton
        style={{ position: 'fixed', bottom: 40, right: 5 }}
        actions={[
          { text: "Reload", onClick: async () => { await load() } },
          {
            text: "Clear", onClick: async () => {
              await appCommand.api.getSimpleStore("/api/utils/admin_tools/clear_logs");
              load()
            }
          }]
        }
      />

      <DCodeDisplay code={code?.length > 0 ? code : "Nothing found"} />
    </DBasePage>
  );
}
