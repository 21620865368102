import { Button } from "@mui/material";
import { TStringCallback, TVoidCalBack } from "corexxx";
import { CSSProperties, useState } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";

export function DTextAreaButton({
  inputStyle,
  extra_btn,
  placeholder,
  onSearch,
  style,
  btn_text,
  value,
  onTextChange,
  onBtnClick,
}: {
  inputStyle?: CSSProperties;
  extra_btn?: any;
  placeholder?: string;
  onBtnClick?: TVoidCalBack;
  onTextChange?: TStringCallback;
  value?: string;
  onSearch?: TStringCallback;
  style?: CSSProperties;
  btn_text?: string;
}) {
  const [searchText, setSearchText] = useState(value || "");
  const handleSearch = () => {
    // Pass the search text to the parent component or perform the search logic here
    onSearch?.(searchText);
    onBtnClick?.();
    setSearchText("");
  };

  const handleInputChange = (event: any) => {
    setSearchText(event.target.value);
    onTextChange?.(event.target.value);
  };
  return (
    <DCol style={{ flex: 0, ...style }}>
      <textarea
        //label="Search"
        // variant="outlined"
        placeholder={placeholder}
        value={searchText}
        onChange={handleInputChange}
        //size="small"
        style={{
          width: "100%",
          fontSize: 12,
          border: "1px solid #1976d2",
          height: 80,
          maxLines: 50,
          padding: 4,
          ...inputStyle,
        }}
      />
      <DRow style={{ marginTop: 5 }}>
        <DSpace />
        {extra_btn}
        <Button
          variant="contained"
          onClick={handleSearch}
          size="small"
          style={{ borderRadius: 0 }}
        >
          {btn_text || "Search"}
        </Button>
      </DRow>
    </DCol>
  );
}
