import { Alert, CircularProgress } from "@mui/material";
import React, { CSSProperties, useState } from "react";
import { TNotification } from "./DAppCommand";
import { DRow } from "./DRow";
import { DText } from "./DText";
export const DAlert = ({
  notification,
  style,
  diable_closable,
  actionJsx
}: {
  notification: TNotification;
  style?: CSSProperties;
  diable_closable?: boolean;
  actionJsx?: any
}) => {
  const [open, setOpen] = useState(true);
  React.useEffect(() => {
    setOpen(true);
  }, [notification]);
  if (!open) {
    return <></>;
  }
  return (
    <Alert
      onClose={diable_closable ? undefined : () => setOpen(false)}
      severity={notification.type}
      sx={{ width: "auto", height: 50 }}
      style={style}
      action={actionJsx}
    >
      <DRow style={{ alignItems: "center" }}>
        {" "}
        {notification.is_loading ? (
          <CircularProgress size={15} style={{ marginRight: 10 }} />
        ) : null}
        <DText html style={{ flex: 1, lineHeight: 1 }}>{notification.msg}</DText>
      </DRow>
    </Alert>
  );
};
