import { dnetwork, TObject } from "corexxx";
import { useState } from "react";
import { DHook } from "../common_hooks/DHook";
import { DText } from "./DText";

export function DTextNetwork({
  url,
  getText,
}: {
  url: string;
  getText: (obj: TObject) => string;
}) {
  let [text, setText] = useState("");
  DHook.useAsyncEffect(async () => {
    try {
      let data = (await dnetwork.get(url)) as TObject;
      setText(getText(data));
    } catch (e: any) {
      setText(e.message);
    }
  }, [url]);

  return <DText>{text}</DText>;
}
