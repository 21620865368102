import { DResponsiveWindow } from "../../dweb/DResponsiveWindow";
import { DPageInConstruction } from "../../dweb/DSamplePages";

export const TestScreen = () => {
  return (
    <DResponsiveWindow>
      <DPageInConstruction />
    </DResponsiveWindow>
  );
};
