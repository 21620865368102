import { PhotoCamera } from "@mui/icons-material";
import { Avatar, Button, IconButton } from "@mui/material";
import { TAnyCallback, dcolors } from "corexxx";
import { CSSProperties } from "react";
import { DButton } from "./DButton";
import { DText } from "./DText";

// export Avatar with Exit Pictire Button
export const DAvatarWithChangePicture = ({
  src,
  alt,
  sx,
  onChange,
}: {
  src?: string;
  onChange?: (files: File) => any;
  sx?: any;
  alt?: string | undefined;
}) => {
  return (
    <div style={{ position: "relative" }}>
      <Avatar src={src} alt={alt} sx={sx} />
      {onChange ? (
        <DUploadButton
          buttonType="icon"
          style={{
            position: "absolute",
            bottom: -10,
            right: -10,
            color: dcolors.pallets.black,
          }}
          onChange={(obj: any) => {
            onChange(obj[0] as File);
          }}
        />
      ) : null}
    </div>
  );
};

export const DUploadButton = ({
  onChange,
  style,
  buttonType,
  fileType
}: {
  onChange: (files: any, event?: any) => void;
  style?: CSSProperties;
  buttonType?: 'icon' | 'normal',
  fileType?: string;// by default image
}) => {
  return buttonType == "icon" ? (<IconButton
    color="primary"
    aria-label="upload picture"
    component="label"
    style={style}
  >
    <input
      hidden
      accept={fileType || "image/*"}
      type="file"
      onChange={(e: any) => {
        onChange(e.target.files, e);
      }}
    />
    <PhotoCamera />
  </IconButton>) : (
    <Button
      color="primary"
      aria-label="upload picture"
      component="label"
      variant="contained"
      style={style}
    >
      <input
        hidden
        accept={fileType || "image/*"}
        type="file"
        onChange={(e: any) => {
          onChange(e.target.files, e);
        }}
      />
      <DText style={{ color: 'white' }}>Upload</DText>
    </Button>

  );
};
