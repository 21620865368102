import { Alert } from "@mui/material";
import { TObject, dcolors, dnetwork } from "corexxx";
import { useState } from "react";
import { DHook } from "../common_hooks/DHook";
import { DCol } from "./DCol";
import { DHtml } from "./DHtml";
import { DRow } from "./DRow";
import { DSearchButton } from "./DSearchButton";
import { DTable, DTableColumn } from "./DTable";
import { DText } from "./DText";

// Splt data veiw is a view of simplestore data with table with expaned view way
export function DSplitDataView({
  url,
  columns,
  getDetailsText,
  buildSearchParams,
}: {
  url: string;
  columns: DTableColumn[];
  getDetailsText: (obj: TObject) => string;
  buildSearchParams: (x: string) => TObject;
}) {
  const networkConn = DHook.useNetwork(url, { isSimpleStoreEndpoint: true });
  const [selected, setSelected] = useState<TObject | undefined>();
  return (
    <div>
      <DCol style={{ height: "50vh", padding: 10, background: "#f1f1f1" }}>
        <DRow
          style={{
            flex: 0,
            paddingTop: 10,
            paddingBottom: 20,
            alignItems: "center",
          }}
        >
          <DText style={{ flex: 1 }}>Data points</DText>
          <DSearchButton
            onSearch={(x) => networkConn.api.setParams(buildSearchParams(x))}
          />
        </DRow>
        {networkConn.state.data ? (
          <DTable
            data={networkConn.state.data as TObject[]}
            columns={columns}
            actions={[
              {
                text: "delete",
                onClick: async (obj) => {
                  await dnetwork.postSimpleStore(`${url}/delete`, {
                    _id: obj._id,
                  });
                  networkConn.api.reload();
                },
              },
            ]}
            onRowClicked={(x) => setSelected(x)}
            style={{ padding: 0 }}
          />
        ) : null}
      </DCol>
      <DCol
        style={{
          background: dcolors.pallets.amber50,
          height: "50vh",
          padding: 10,
          borderTop: "3px solid black",
        }}
      >
        {!selected ? (
          <Alert severity="error">Please select some item from top</Alert>
        ) : null}
        {selected ? <DHtml html={getDetailsText(selected)} /> : null}
      </DCol>
    </div>
  );
}
