import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { TStringCallback } from "corexxx";
import { CSSProperties } from "react";
import { convertToTextValue, TOptions } from "./DRadioButton";

export function DSelectInput({
  label,
  value,
  options,
  onChange,
  style,
  dark
}: {
  style?: CSSProperties;
  label?: string;
  value: string;
  options: TOptions;
  dark?: boolean
  onChange: TStringCallback;
}) {
  return (
    <FormControl variant="outlined" style={{ width: 100, borderColor: dark ? 'white' : 'back', ...style }}>
      {label ? (
        <InputLabel id="simple-select-l?abel">{label}</InputLabel>
      ) : null}
      <Select
        size="small"
        labelId="simple-select-label"
        id="simple-select"
        style={{ color: dark ? 'white' : 'black' }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label="Age"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {convertToTextValue(options).map((y, idy) => (
          <MenuItem key={idy} value={y.value}>
            {y.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
