import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TObjCalBack, TObject, dcolors, dtime } from "corexxx";
import React, { CSSProperties, useState } from "react";
import _ from "underscore";
import { DWebTS } from "../common_ts/DWebTS";
import { DChipActionList } from "./DChipActionList";
import { DDropDownMenu } from "./DDropdownMenu";
import { DText } from "./DText";
import { DRow } from "./DRow";
export type DTableColumn = {
  view_type?:
  | "icon_button"
  | "time"
  | "datetime"
  | "link"
  | "click_to_open"
  | "phone"
  | "email"
  | "multiple_link"
  | "link_with_custom_text"
  | "two_decimal"
  | "json";
  onClick?: (y: any, index: number) => void;
  field: string;
  headerName?: string;
  format?: (arg: any, obj: TObject) => any;
  formatEx?: (arg: string, obj: TObject) => JSX.Element;
  style?: CSSProperties;
  styleFn?: (arg: any, obj: TObject) => CSSProperties;
  sortFn?: (a: any, b: any) => number;
  icon?: JSX.Element; // icon_button
  hover_text?: (a: any) => string;
};
// We must memo the Table as it slowdoan the rendering when huge data
export const DTable = React.memo(
  ({
    data,
    columns,
    style,
    actions,
    inline_buttons_actions, // will create chip like button on right side
    onRowClicked,
    height,
    config
  }: {
    data: TObject[];
    columns?: DTableColumn[];
    style?: CSSProperties; // this is a row style
    actions?: { text: string; onClick: (obj: TObject, idx?: number) => void }[];
    inline_buttons_actions?: {
      text: string;
      onClick: (obj: TObject, idx?: number) => void;
      style?: CSSProperties;
    }[];
    onRowClicked?: TObjCalBack;
    height?: any;
    config?: { show_count?: boolean, title?: string }
  }) => {
    const [data1, setdata1] = useState(data || []);
    React.useEffect(() => setdata1(data || []), [data]);
    const [sortOrder, setSortOrder] = useState(true);

    function getCol() {
      if (columns) {
        return columns;
      }
      if (data.length > 0) {
        let res = Object.keys(data[0]).map((x) => ({
          field: x,
        }));
        return res;
      }
      return [];
    }

    const [columns1, setcolumns1] = useState<DTableColumn[]>(getCol());

    React.useEffect(() => {
      setcolumns1(getCol());
    }, [columns, data]);

    return (
      <TableContainer

        component={Paper}
        style={{
          position: "relative",
          maxHeight: height || 500,
          overflowY: "auto",
          ...style,
        }}
      >
        {config?.title ? <DText style={{ padding: 5, background: dcolors.pallets.yellow100, textAlign: 'center' }}>{config.title}</DText> : null}
        <Table style={{ position: "relative", color: dcolors.pallets.grey400 }}>
          <TableHead
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffffff",
              zIndex: 1,
            }}
          >
            <TableRow>
              {columns1.map((x, idx) => (
                <TableCell
                  size="small"
                  style={{
                    cursor: x.sortFn ? "pointer" : "pointer",
                    fontWeight: "bolder",
                    textTransform: "capitalize",
                  }}
                  key={idx}
                  onClick={() => {
                    if (x.sortFn) {
                      let p = [...data1].sort(x.sortFn);
                      if (sortOrder) {
                        p.reverse();
                      }
                      setSortOrder(!sortOrder);
                      setdata1(p);
                    }
                  }}
                >
                  {x.headerName || x.field.replace("_", " ")}{" "}
                </TableCell>
              ))}
              {actions ? (
                <TableCell size="small" style={{ textAlign: "right" }}>
                  Operation
                </TableCell>
              ) : null}
              {inline_buttons_actions ? (
                <TableCell size="small" style={{ textAlign: "right" }}>
                  Operation
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody style={{ paddingTop: 40 }}>
            {(data1 || []).map((x, idx) => {
              let actions1 = actions?.map((x1) => ({
                text: x1.text,
                onClick: () => {
                  x1.onClick(x, idx);
                },
              }));

              let actions2 = inline_buttons_actions?.map((x1) => ({
                text: x1.text,
                onClick: () => {
                  x1.onClick(x, idx);
                },
                style: x1.style,
              }));

              return (
                <TableRow
                  key={idx}
                  onClick={() => {
                    onRowClicked?.(x);
                  }}
                >
                  {columns1.map((y, idy) => {
                    let d = x[y.field];
                    let style = Object.assign(
                      {},
                      y.style || {},
                      y.styleFn?.(d, x) || {},
                    );

                    if (y.formatEx) {
                      return (
                        <TableCell size="small" key={idy} style={style}>
                          {y.formatEx(d, x)}
                        </TableCell>
                      );
                    }
                    if (y.format) {
                      d = y.format(d, x);
                    }

                    switch (y.view_type) {
                      case "time":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <DText
                              title={
                                y.hover_text?.(x) || dtime.getFormattedDate(d)
                              }
                            >
                              {dtime.getFormattedDate(d)}
                            </DText>
                          </TableCell>
                        );
                      case "datetime":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <DText
                              title={
                                y.hover_text?.(x) ||
                                isoToDetailedHumanReadable(d)
                              }
                            >
                              {isoToDetailedHumanReadable(d)}
                            </DText>
                          </TableCell>
                        );
                      case "json":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <DText title="">{JSON.stringify(d)}</DText>
                          </TableCell>
                        );
                      case "two_decimal":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <DText>{parseFloat(d).toFixed(2)}</DText>
                          </TableCell>
                        );
                      case "icon_button":
                        return (
                          <TableCell key={idy} style={style}>
                            <IconButton onClick={() => y.onClick?.(x, idx)}>
                              {y.icon}
                            </IconButton>
                          </TableCell>
                        );
                      case "phone":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <a
                              href={`tel:` + d}
                              target="_blank"
                              title={y.hover_text?.(x)}
                            >
                              {d}
                            </a>
                          </TableCell>
                        );
                      case "email":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <a
                              href={`mailto:` + d}
                              target="_blank"
                              title={y.hover_text?.(x)}
                            >
                              {d}
                            </a>
                          </TableCell>
                        );
                      case "link":
                        return (
                          <TableCell
                            size="small"
                            key={idy}
                            style={style}
                            title={y.hover_text?.(x)}
                          >
                            <a href={d} target="_blank">
                              link
                            </a>
                          </TableCell>
                        );
                      case "link_with_custom_text":
                        return (
                          <TableCell size="small" key={idy} style={style}>
                            <a
                              href={d[0]}
                              target="_blank"
                              title={y.hover_text?.(x)}
                            >
                              {d[1]}
                            </a>
                          </TableCell>
                        );
                      case "click_to_open":
                        return (
                          <TableCell
                            size="small"
                            key={idy}
                            style={style}
                            title={y.hover_text?.(x)}
                          >
                            <Chip
                              label={"Click to view"}
                              onClick={() => {
                                y?.onClick?.(d, idx);
                              }}
                            />
                          </TableCell>
                        );
                      case "multiple_link":
                        let links = DWebTS.getArray(d) as string[];
                        return (
                          <TableCell size="small" style={style} key={idy}>
                            {links.map((yy, idyy) => (
                              <Chip
                                key={idyy}
                                style={{ marginRight: 4 }}
                                label={"link #" + (idyy + 1)}
                                onClick={() => {
                                  y?.onClick?.(yy, idx);
                                }}
                              />
                            ))}
                          </TableCell>
                        );
                      default:
                        return (
                          <TableCell
                            size="small"
                            title={y.hover_text?.(x)}
                            style={style}
                            key={idy}
                          >
                            {_.isUndefined(d) || _.isNull(d) ? "-" : d + ""}
                          </TableCell>
                        );
                    }
                  })}
                  {actions ? (
                    <TableCell size="small" style={{ textAlign: "right" }}>
                      <DDropDownMenu actions={actions1 || []} />
                    </TableCell>
                  ) : null}
                  {inline_buttons_actions ? (
                    <TableCell size="small" style={{ textAlign: "right" }}>
                      <DChipActionList actions={actions2 || []} />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {config?.show_count && data?.length == 0 ? (
          <DText style={{ flex: 1, textAlign: "center", padding: 15 }}>
            {" "}
            No data found
          </DText>
        ) : null}
        {config?.show_count ? <DRow style={{ padding: 10 }}>
          <DText style={{ fontWeight: 10 }}>{data?.length || 0} entry found!</DText>
        </DRow> : null}
      </TableContainer>
    );
  },
);

function isoToDetailedHumanReadable(isoString: string) {
  const date = new Date(isoString);

  // Extract date components
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const month = monthNames[date.getMonth()];
  const dayName = dayNames[date.getDay()];
  const year = date.getFullYear();

  // Add ordinal suffix to the day
  const ordinalSuffix = (day: number) => {
    if (day % 10 === 1 && day !== 11) return `${day}st`;
    if (day % 10 === 2 && day !== 12) return `${day}nd`;
    if (day % 10 === 3 && day !== 13) return `${day}rd`;
    return `${day}th`;
  };
  if (Number.isNaN(year)) {
    return '-'
  }

  return `${ordinalSuffix(day)} of ${month}, ${dayName}, ${year}`;
}
