import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { TVoidCalBack, dlog } from "corexxx";
import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
export const DNestedMenu = ({
  menu,
  onClick,
}: {
  menu: {
    title?: string;
    path?: string;
    child?: { title: string; path?: string; onClick?: TVoidCalBack }[];
    icon?: any;
    onClick?: TVoidCalBack;
    style?: CSSProperties
  };
  onClick: (idx: number) => void;
}) => {
  // we need mutiple anchorEl for each nested menu
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const navigate = useNavigate();
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!menu.child) {
    return <Button onClick={menu.onClick} style={menu.style}>{menu.title}</Button>;
  }

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={() => {
          menu.onClick?.();
        }}
      >
        {menu.child?.map((x, idx) => (
          <MenuItem
            onClick={() => {
              dlog.d("Test");
              handleMenuClose();
              if (!menu.path && x.path) {
                navigate(x.path);
              }
              if (menu.path && x.path) {
                navigate(menu.path + x.path);
              }
              x.onClick?.();
              onClick?.(idx);
            }}
            key={idx}
          >
            {x.title}
          </MenuItem>
        ))}
      </Menu>
      {menu.title ? (
        <Button
          onClick={handleMenuClick}
          style={{ color: "white", textTransform: "capitalize", ...menu.style }}
        >
          {menu.title}
        </Button>
      ) : null}
      {menu.icon ? (
        <IconButton onClick={handleMenuClick}>{menu.icon}</IconButton>
      ) : null}
    </div>
  );
};
