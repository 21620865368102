import { Button, CircularProgress } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { CSSProperties } from "react";
import { useDAppCommand } from "./DAppCommand";

export function DButton({
  text,
  onClick,
  icon,
  style,
  loading,
  variant,
  disabled,
  children,
  href
}: {
  children?: any;
  text?: string;
  onClick?: TVoidCalBack;
  icon?: JSX.Element;
  style?: CSSProperties;
  loading?: boolean;
  variant?: "text" | "contained" | "outlined"
  disabled?: boolean
  href?: string
}) {
  let appCommand = useDAppCommand();
  // Note that either you pass onClick directly or need to handle async
  return (
    <Button
      disabled={disabled || loading}
      href={href}
      size="small"
      variant={variant || "contained"}
      onClick={onClick}
      style={{
        flex: 'none',
        backgroundColor: appCommand.getAppConfig()?.primary_color,
        backgroundImage: appCommand.getAppConfig()?.primary_background,
        ...style,
      }}
    >
      {loading ? (
        <CircularProgress
          color="inherit"
          size={14}
          style={{ marginRight: 5, color: "#fffff99" }}
        />
      ) : null}
      <span style={{ marginRight: 3, marginTop: 0, fontSize: 5 }}>{icon}</span>
      {text || children}
    </Button>
  );
}
