import { DHook } from "@libs/common_hooks/DHook";
import { DBox } from "@libs/dweb/DBox";
import { DBarChart, DLineChart } from "@libs/dweb/DChart";
import { DashboardTiles } from "@libs/dweb/DDashboard";
import { DRefreshButton } from "@libs/dweb/DRefreshButton";
import { DSelectInput } from "@libs/dweb/DSelectInput";
import { DTabView } from "@libs/dweb/DTabView";
import { dcolors } from "corexxx";
import React, { useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DBasePage } from "../App";

export function AppInsightsPage() {
  const [appId, setAppId] = useState<string>('simplestore_admin');
  return (
    <DBasePage>
      <DTabView
        style={{ marginTop: 20 }}
        extraRightView={<DSelectInput label="App ID" style={{ width: 200 }} value={appId} onChange={setAppId} options={["simplestore_admin", "in.peerreview.fmradioindia", "NO_APPID"]} />}
        config={{
          tabview: "chip",
        }}
        data={[
          {
            text: "Summary",
            view: <Summary app_id={appId} />,
          },
          {
            text: "AppLaunch",
            view: <AppLaunch app_id={appId} />,
          },
          {
            text: "AppException",
            view: <AppException app_id={appId} />,
          },
          {
            text: "Log Distribution",
            view: <LogDistrubution app_id={appId} />,
          },

        ]}
      />
    </DBasePage>
  );
}


const Summary = ({ app_id }: { app_id: string }) => {
  return (
    <DBox>
      <DashboardTiles
        data={[
          {
            title: "DAU",
            subtext: "Daily Active Users/sessions",
            value: "0",
            color: dcolors.pallets.green700
          },
          {
            title: "WAU",
            subtext: "Weekly Active Users/sessions",
            value: "0",
            color: dcolors.pallets.blue700
          },
          {
            title: "Average Session Time",
            subtext: "Average session time",
            value: "0",
            color: dcolors.pallets.yellow900
          },
          {
            title: "Product Exceptions",
            subtext: "Number of exceptions",
            value: "0",
            color: dcolors.pallets.red700
          },
          {
            title: "Unique Crash Users",
            subtext: "Unique users who crashed",
            value: "0",
            color: dcolors.pallets.pink700
          }
        ]}
      />
    </DBox>
  );
}
//
const AppLaunch = ({ app_id }: { app_id: string }) => {
  let data = DHook.useNetwork(`/api/analytics/ts?app_id=${app_id}&type=launch`)

  let distrubtion = DHook.useNetwork(`/api/analytics/distribution?_field=device_os&app_id=${app_id}`)//https://simplestore.dipankar.co.in/api/analytics/distribution?app_id=simplestore_admin&_field=device_os
  return (
    <DBox header={{ title: "App Launches by Date", rightJsx: <DRefreshButton btn onClick={data.api.reload} /> }} >
      <DLineChart
        text="App Launches by Date"
        data={data?.state?.data?.out || []}
        xLevel="date"
        yLevel="count"
        xDataKey="day"
        yDataKey="count"
      />
      <DBarChart
        text="Distribution by OS and Browser"
        data={distrubtion?.state?.data?.out || []}
        xLevel="OS"
        yLevel="count"
        xDataKey="device_os"
        yDataKey="count"
      />
    </DBox>
  );
}

const AppException = ({ app_id }: { app_id: string }) => {
  let data = DHook.useNetwork(`/api/analytics/ts?app_id=${app_id}&type=exception`)
  return (
    <DBox header={{ title: "App Exception by Date", rightJsx: <DRefreshButton onClick={data.api.reload} /> }}>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data?.state?.data?.out || []}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="day" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="count" stroke="#8884d8" strokeWidth={2} />
        </LineChart>

      </ResponsiveContainer>
    </DBox>
  );
}

//https://simplestore.dipankar.co.in/api/analytics/distribution?_field=app_id
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const LogDistrubution = ({ app_id }: { app_id: string }) => {
  let [data, setData] = useState<any[]>([]);

  let { state, api } = DHook.useNetwork(`/api/analytics/distribution?_field=app_id`)
  React.useEffect(() => {
    if (state.data) {
      setData(state.data.out);
    }
  }, [state.data]);
  return (
    <DBox header={{ title: "App Exception by Date", rightJsx: <DRefreshButton btn onClick={api.reload} /> }}>
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={state.data?.out || []}
            dataKey="count"
            nameKey="app_id"
            cx="50%"
            cy="50%"
            outerRadius={100}
            label
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>

      {/* Bar Chart */}
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <XAxis dataKey="app_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" fill="#82ca9d" />
        </BarChart>
      </ResponsiveContainer>
    </DBox>
  );
}