import { useState, useRef, FC, CSSProperties } from "react";

type DHozSplitViewProps = {
    style?: CSSProperties
    topComponent: JSX.Element;
    bottomComponent: JSX.Element;
};

// this wil create a component with a Hoz Split view
const DHozSplitView: FC<DHozSplitViewProps> = ({ style, topComponent, bottomComponent }) => {
    const [height, setHeight] = useState<string>("50%");
    const isResizing = useRef<boolean>(false);

    const startResize = (e: React.MouseEvent<HTMLDivElement>) => {
        isResizing.current = true;
        document.body.style.userSelect = "none";
        document.addEventListener("mousemove", resize);
        document.addEventListener("mouseup", stopResize);
    };

    const resize = (e: MouseEvent) => {

        if (isResizing.current) {
            const container = document.querySelector('.split-container') as HTMLDivElement;
            const rect = container.getBoundingClientRect();
            const newHeight = ((e.clientY - rect.top) / container.clientHeight) * 100;
            setHeight(`${newHeight}%`);
            //const newHeight = (e.clientY / window.innerHeight) * 100;
            //setHeight(`${newHeight}%`);
        }
    };

    const stopResize = () => {
        document.body.style.userSelect = "";
        isResizing.current = false;
        document.removeEventListener("mousemove", resize);
        document.removeEventListener("mouseup", stopResize);
    };

    return (
        <div className="split-container" style={{ height: "100%", display: "flex", flexDirection: "column", width: '100%', border: '2px solid #ccc', ...style }}>
            <div style={{ height, overflow: "scroll" }}>{topComponent}</div>
            <div
                style={{ height: "8px", background: "#ccc", cursor: "row-resize" }}
                onMouseDown={startResize}
            ></div>
            <div style={{ flex: 1, overflow: "scroll" }}>{bottomComponent}</div>
        </div>
    );
};

export default DHozSplitView;
