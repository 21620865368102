import axios from 'axios';
import React, { useState } from 'react';
import { DTable } from './DTable';

interface UploadCareFileUploadProps {
    publicKey: string;
}

export const UploadCareFileUpload: React.FC<UploadCareFileUploadProps> = ({ publicKey }) => {
    const [files, setFiles] = useState<File[]>([]);
    const [uploadingFiles, setUploadingFiles] = useState<
        { file: File; progress: number; url?: string }[]
    >([]);
    const [error, setError] = useState<string>('');

    // Handle multiple files selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFiles = Array.from(event.target.files);
            setFiles(selectedFiles);
        }
    };

    // Upload files and show progress
    const handleFileUpload = async () => {
        let filesToUpload = files.map((file) => ({
            file,
            progress: 0,
        }))
        setUploadingFiles(filesToUpload);
        setError('');
        try {
            const uploadPromises = files.map((file, index) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('UPLOADCARE_PUB_KEY', publicKey);

                // Upload the file and update the progress
                return axios.post('https://upload.uploadcare.com/base/', formData, {
                    headers: formData.entries(),
                    onUploadProgress: (progressEvent: any) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadingFiles((prevState) => {
                            const updatedFiles = [...prevState];
                            updatedFiles[index] = { ...updatedFiles[index], progress };
                            return updatedFiles;
                        });
                    },
                });
            });

            // Wait for all uploads to complete
            const responses = await Promise.all(uploadPromises);

            // After successful upload, get the URLs
            const newUploadingFiles = filesToUpload.map((file, index) => {
                return {
                    ...file,
                    file_name: file.file.name,
                    size: file.file.size,
                    progress: 100,
                    url: `https://ucarecdn.com/${responses[index].data.file}/`,
                };
            });
            debugger
            setUploadingFiles(newUploadingFiles);
        } catch (err) {
            setError('Failed to upload files.');
        }
    };

    return (
        <div>
            <h3>Upload Files</h3>

            <input type="file" multiple onChange={handleFileChange} />

            <button onClick={handleFileUpload} disabled={files.length === 0}>
                Upload {files.length > 0 ? `(${files.length} files)` : ''}
            </button>

            {uploadingFiles.length > 0 && (<DTable style={{ marginTop: 10 }} data={uploadingFiles}
                columns={[
                    {
                        field: 'file_name',
                    },
                    {
                        field: 'url',
                    },
                    {
                        field: 'size',
                    },
                    {
                        field: 'url',
                        view_type: 'link',
                    },
                ]} />)}


            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};
