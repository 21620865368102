import { DHook } from "@libs/common_hooks/DHook";
import { SimpleStoreClient } from "@libs/common_ts/SimpleStoreClient";
import { DefaultAccountScreen } from "@libs/components/screens/DefaultAccountScreen";
import { DefaultPageInProgress } from "@libs/components/screens/DefaultPageInProgress";
import {
  TAppConfig,
  setAppConfig,
  useDAppCommand,
} from "@libs/dweb/DAppCommand";
import { DAppWrapper } from "@libs/dweb/DAppWrapper";
import { DHelmet } from "@libs/dweb/DHelmet";
import { DPageLoadingWrapper } from "@libs/dweb/DPageLoadingWrapper";
import { dlog } from "corexxx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { useAdmin } from "./Hook";
import { DashBoard } from "./pages/DashboardPage";
import { LandingPage } from "./pages/LandingPage";

// Step1: Init
let appConfig: TAppConfig = {
  app_id: "simplestore_admin",
  app_name: "SimpleStore Admin",
  primary_color: "#1e88e5",
  app_slogan: "Manage SimpleStore Server online",
  app_subtext: "Manage SimpleStore Server online",
  logo: require("./focus/favicon_io/android-chrome-512x512.png"),
};
SimpleStoreClient.Get().init(appConfig);
setAppConfig(appConfig);

export function DBasePage({ children }: { children: any }) {
  return (
    <DPageLoadingWrapper style={{ marginTop: 5 }}>
      {children}
    </DPageLoadingWrapper>
  );
}

export function AdminInit() {
  let admin = useAdmin();
  let appCommand = useDAppCommand();
  DHook.useAsyncEffect(async () => {
    let data = await appCommand.api.get(
      appCommand.state.simpleStoreEndpoint + "/info",
    );
    admin.api.setInfo(data);
    dlog.i("admin init done");
  }, [appCommand.state.simpleStoreEndpoint]);
  return <></>;
}

function App() {
  return (
    <RecoilRoot>
      <DHelmet config={{ page_title: "Home Page" }} />
      <DAppWrapper appConfig={appConfig}>
        <BrowserRouter>
          <AdminInit />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/account" element={<DefaultAccountScreen />} />
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="*" element={<DefaultPageInProgress />} />
          </Routes>
        </BrowserRouter>
      </DAppWrapper>
    </RecoilRoot>
  );
}

export default App;
