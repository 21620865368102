import { TObject } from "corexxx";
import Chart from "react-apexcharts";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, Treemap, XAxis, YAxis } from "recharts";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";
const colorPalette = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#FFC107",
];

function generateColors(num: number) {
  const colors = [];
  for (let i = 0; i < num; i++) {
    colors.push(colorPalette[i % colorPalette.length]);
  }
  return colors;
}

export function PieChart() {
  let options: ApexCharts.ApexOptions = {
    grid: {
      show: false,
    },

    chart: {
      id: "basic-bar",
      zoom: {
        enabled: false,
      },
      height: 100,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };
  let series = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ];

  return <Chart type="area" series={series} options={options} />;
}
export const ColorBar = ({ config }: { config: TObject }) => {
  let total = Object.values(config).reduce((acc, curr) => acc + curr, 0);
  return (
    <DRow style={{ flex: 0 }}>
      {Object.keys(config).map((x) => (
        <div
          style={{
            height: 5,
            width: `${(config[x] / total) * 100}%`,
            background: x,
          }}
          key={x}
        />
      ))}
    </DRow>
  );
};

export const DTreeMap = ({
  data,
}: {
  data: { title: string; value: number }[];
}) => {
  // Prepare the data for the tree map
  const processedData = data.map((item) => ({
    name: item.title,
    size: item.value,
    fill: item.value > 0 ? "green" : "red",
    strokeWidth: 1,
    textColor: "red",
  }));

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <Treemap
          width={730}
          height={250}
          isAnimationActive={false}
          data={processedData}
          dataKey="size"
          fill="white" // textcolor
          style={{ color: "red" }}
        >
          <Tooltip />
        </Treemap>
      </ResponsiveContainer>
    </div>
  );
};


// Bar chart
export function DBarChart({ text, data, xLevel, yLevel, xDataKey, yDataKey }: { text: string, data: any[], xLevel: string, yLevel: string, xDataKey: string, yDataKey: string }) {
  return (<DCol><DText style={{ marginBottom: 20 }}>{text}</DText>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <XAxis dataKey={xDataKey} label={{
          value: yLevel + '⟶',
          //angle: -90,  // Rotate label 90 degrees counterclockwise
          position: 'insideLeft',  // Position the label inside the chart
          dy: 10,
          fill: 'black',
          fontSize: 14,
          dx: 40,  // Adjust the position horizontally if needed
        }} />
        <YAxis
          label={{
            value: yLevel + '⟶',
            fontSize: 14,
            fill: 'black',
            angle: -90,  // Rotate label 90 degrees counterclockwise
            position: 'insideMiddle',  // Position the label inside the chart
            dx: 0,  // Adjust the position horizontally if needed
          }}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey={yDataKey} fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer></DCol>)
}

export function DLineChart({ text, data, xLevel, yLevel, xDataKey, yDataKey }: { text: string, data: any[], xLevel: string, yLevel: string, xDataKey: string, yDataKey: string }) {
  return (<DCol><DText style={{ marginBottom: 20 }}>{text}</DText>
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey} label={{
          value: yLevel + '⟶',
          //angle: -90,  // Rotate label 90 degrees counterclockwise
          position: 'insideLeft',  // Position the label inside the chart
          dy: 10,
          fill: 'black',
          fontSize: 14,
          dx: 40,  // Adjust the position horizontally if needed
        }} />
        <YAxis
          label={{
            value: yLevel + '⟶',
            fontSize: 14,
            fill: 'black',
            angle: -90,  // Rotate label 90 degrees counterclockwise
            position: 'insideMiddle',  // Position the label inside the chart
            dx: 0,  // Adjust the position horizontally if needed
          }}
        />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={yDataKey} stroke="#8884d8" strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer></DCol>)
}