import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DText } from "./DText";

export function DTextOr({ text }: { text?: string }) {
  return (
    <DRow style={{ marginTop: 10, marginBottom: 10 }}>
      <DSpace
        style={{
          borderTop: "1px dashed grey",
          marginTop: 10,
          height: 1,
          marginRight: 5,
        }}
      />
      <DText style={{ paddingLeft: 5, paddingRight: 5 }}>{text || 'OR'}</DText>
      <DSpace
        style={{
          borderTop: "1px dashed grey",
          marginTop: 10,
          height: 1,
          marginLeft: 5,
        }}
      />
    </DRow>
  );
}
