import { OnePager } from "@libs/components/simpleonepager/OnePager";
import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { PageHeaderMenu } from "@libs/dweb/DPageHeaderMenu";

export function LandingPage() {
  let appCommand = useDAppCommand()
  return (
    <OnePager
      extraElement={
        <PageHeaderMenu
          logo={require("../asserts/img/logo.png")}
          rightDropDownActions={[{ text: "dashboard", href: "/dashboard" }]}
          style={{ backgroundColor: "#00000000" }}
        />
      }
      config={{
        hero_section: {
          hero_title: "Powerful backend infrastructure for rapid prototyping",
          hero_subtitle: "",
          hero_image_src: "",
          primary_btn_link: "/dashboard",
          primary_btn_subtext:
            'Please fill-up the form if you want to use SimpleStore API"s',
          primary_btn_title: "Explore Dashboard ⇒ ",
        },
        generic_section: [
          {
            groupOfThree: [
              {
                title: "100+ Global supported APIs",
                subtitle: "It covers from CRUD, Auth, SSO, payment etc",
                btn_text: "Show me all API",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
              {
                title: "99% reliabilities Matrix",
                subtitle: "All APIs are supported by e2e automation testes ",
                btn_text: "Learn readability Matrix",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
              {
                title: "Build-in Operations API's ",
                subtitle:
                  "Build-in support for logging, analytics, crash logs and SEV Managers ",
                btn_text: "Known operation",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
            ],
          },

          {
            groupOfThree: [
              {
                title: "Powerful Sign-Up API",
                subtitle: "password, token, magic link, social and SSO login",
                btn_text: "know more",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
              {
                title: "Payment APIs",
                subtitle: "Supported international payment gateways",
                btn_text: "Learn readability Matrix",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
              {
                title: "Simplified Database API's",
                subtitle:
                  "CRUD and filtering is much powerful with SimpleStore ",
                btn_text: "Known operation",
                btn_link: appCommand.state.simpleStoreEndpoint + "/api/",
              },
            ],
          },
        ],
        footer_section: {
          author_email: "dsofthelp@outlook.com",
          author_text: "Dipankar",
          author_link: "/",
          privacy_link: "/",
          toc_link: "/",
        },
      }}
    />
  );
}
