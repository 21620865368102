import { dcolors } from "corexxx"
import { DBox } from "./DBox"
import { DText } from "./DText"

export function DTutorial({
    helperText,
    description,
    dos,
    donts
}: {
    helperText: string,
    description: string, dos: string[], donts: string[]
}) {
    return (
        <DBox
            header={{ title: helperText }}
            innerStyle={{ border: 0 }}
            style={{ flex: 1, margin: 10, border: 0, background: dcolors.pallets.blue100 }}
        >
            <DText style={{ marginBottom: 10 }}>{description}</DText>
            <DText style={{ fontWeight: 'bold' }}>Dos:</DText>
            <ul>
                {dos.map((x, i) => <li key={i}>✅ {x}</li>)}
            </ul>
            <DText style={{ fontWeight: 'bold' }}>Donts:</DText>
            <ul>
                {donts.map((x, i) => <li key={i}>❌ {x}</li>)}
            </ul>
        </DBox>
    )
}