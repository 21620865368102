import ErrorIcon from "@mui/icons-material/Error";
import HeartBrokenRoundedIcon from "@mui/icons-material/HeartBrokenRounded";
import { dcolors } from "corexxx";
import { TBasicInfo } from "./DAppCommand";
import { DCol } from "./DCol";
import { DResponsiveWindow } from "./DResponsiveWindow";
import { DText } from "./DText";
import { DTextEmptyView } from "./DTextEmptyView";

export const DNotFound = ({ info }: { info?: TBasicInfo }) => {
  return (
    <DResponsiveWindow>
      <DCol style={{ alignItems: "center", width: 250, margin: "0 auto" }}>
        <HeartBrokenRoundedIcon
          style={{
            fontSize: 80,
            margin: 20,
            textAlign: "center",
            color: dcolors.pallets.grey500,
          }}
        />
        <DText
          style={{
            textAlign: "center",
            fontSize: 20,
            color: dcolors.pallets.grey500,
          }}
        >
          {" "}
          {info?.title || "PAGE NOT FOUND"}
        </DText>
        <DText
          style={{
            textAlign: "center",
            fontSize: 14,
            color: dcolors.pallets.grey600,
            marginTop: 10,
          }}
        >
          {info?.subtitle ||
            "The page you are looking for is not exist. Please check your url."}
        </DText>
        {info?.extra}
      </DCol>
    </DResponsiveWindow>
  );
};

export const DPageInConstruction = () => {
  return (
    <DTextEmptyView
      icon={<img src={require("../img/error.png")}></img>}
      title="This feature is under construction!"
      subtitle="Hold on! We are working on this feature. Please revisit this page after sometime."
    />
  );
};

export const PageNotFound = () => {
  return (
    <DNotFound />
  );
};

export const ErrorPage = ({
  title,
  subtitle,
  extra,
}: {
  title: string;
  subtitle: string;
  extra: any;
}) => {
  return (
    <DResponsiveWindow>
      <div
        style={{
          margin: "0 auto",
          textAlign: "center",
          width: 400,
          marginTop: 40,
        }}
      >
        <ErrorIcon
          style={{
            color: dcolors.pallets.red700,
            fontSize: 140,
            marginTop: 40,
            marginBottom: 0,
          }}
        />
        <DText mode="title" style={{ fontSize: 40, marginTop: 10 }}>
          {title}
        </DText>
        <DText mode="subtitle" style={{ marginTop: 20 }}>
          {subtitle}
        </DText>
        {extra}
      </div>
    </DResponsiveWindow>
  );
};

export const EmptyView = ({
  title,
  subtitle,
  icon,
  extra,
}: {
  title?: string;
  subtitle?: string;
  icon?: string;
  extra?: any;
}) => {
  return (
    <DCol
      style={{
        margin: "0 auto",
        textAlign: "center",
        width: 400,
        marginTop: 40,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ErrorIcon
        style={{
          color: dcolors.pallets.red700,
          fontSize: 140,
          marginTop: 40,
          marginBottom: 0,
        }}
      />
      <DText
        style={{
          fontSize: 24,
          marginTop: 10,
          textTransform: "uppercase",
          letterSpacing: 1.5,
          fontWeight: "bold",
        }}
      >
        {title}
      </DText>
      <DText style={{ marginTop: 5, fontSize: 20, opacity: 0.5 }}>
        {subtitle}
      </DText>
      {extra}
    </DCol>
  );
};
