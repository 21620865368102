import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { CSSProperties } from "react";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DText } from "./DText";

export function DTextWithControl({
    backBtnCallback,
    text,
    rightJsx,
    style,
    dark,
}: {
    backBtnCallback?: TVoidCalBack;
    text: string;
    rightJsx?: any;
    style?: CSSProperties;
    dark?: boolean;
}) {
    return (
        <DRow
            style={{
                flex: 0,
                alignItems: "center",
                ...style,
            }}
        >
            {backBtnCallback ? (
                <IconButton style={{ marginRight: 20 }} onClick={backBtnCallback}>
                    <ArrowBack />
                </IconButton>
            ) : null}
            <DText dark={dark} style={{ fontSize: 20, fontWeight: "bolder" }}>
                {text}
            </DText>
            <DSpace />
            {rightJsx}
        </DRow>
    );
}
