import { Chip } from "@mui/material";
import { TVoidCalBack } from "corexxx";
import { CSSProperties, useState } from "react";

// chip actions list
export const DChipActionList = ({
  actions,
  style,
}: {
  actions: {
    icon?: any;
    text?: string;
    onClick: TVoidCalBack;
    style?: CSSProperties;
  }[];
  style?: CSSProperties;
}) => {
  let [active, setActive] = useState<number>(0);
  return (
    <div style={style}>
      {actions.map((x, idx) => {
        let style: any = {};
        if (idx != 0) {
          style.borderTopLeftRadius = 0;
          style.borderBottomLeftRadius = 0;
          style.borderLeft = "1px solid white";
        }
        if (idx != actions.length - 1) {
          style.borderTopRightRadius = 0;
          style.borderBottomRightRadius = 0;
        }
        return (
          <Chip
            key={idx}
            icon={x.icon}
            label={x.text}
            onClick={() => {
              setActive(idx);
              x.onClick();
            }}
            style={{ ...x.style, ...style, opacity: idx == active ? 1 : 0.6 }}
          ></Chip>
        );
      })}
    </div>
  );
};
