import { TObject } from "corexxx";
import { atom, useRecoilState } from "recoil";
import { useDAppCommand } from "@libs/dweb/DAppCommand";

const EndpointInfoState = atom<TObject | null>({
  key: "endpoint_info_atom",
  default: {},
});

export const useAdmin = () => {
  const appCommand = useDAppCommand(); // app command
  let [info, setInfo] = useRecoilState(EndpointInfoState);
  return {
    state: {
      info: info,
    },
    api: {
      setInfo: setInfo,
    },
  };
};
