import { CSSProperties } from "react";
import { DCol } from "./DCol";
import { DRow } from "./DRow";
import { DText } from "./DText";
export const DBox = ({
  children,
  style,
  innerStyle,
  header
}: {
  children?: any;
  style?: CSSProperties;
  innerStyle?: CSSProperties;
  header?: { title: string, rightJsx?: any, leftJsx?: any }
}) => {
  return (
    <DCol
      style={{
        borderRadius: 4,
        display: "flex",
        background: "white",
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
        position: "relative",
        marginTop: 10,
        marginBottom: 20,
        ...style,
      }}
    >
      {header ? (<DRow center style={{ flex: 0, padding: 16, borderBottom: "1px solid #f1f1f1" }}>
        {header.leftJsx}
        <DText
          style={{ flex: 1, fontWeight: 'bold', color: 'black' }}>{header.title}</DText>
        <div>{header.rightJsx}</div></DRow>) : null}
      <DCol style={{ padding: 20, ...innerStyle }}>
        {children || null}
      </DCol>

    </DCol>
  );
};
