import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DSplitDataView } from "@libs/dweb/DSplitDataView";
import { DBasePage } from "../App";

export function SevManager() {
  let appCommand = useDAppCommand()
  return (
    <DBasePage>
      <DSplitDataView
        url={appCommand.state.simpleStoreEndpoint + "/api/sev_manager"}
        columns={[
          {
            field: "title",
            headerName: "title",
          },

          {
            field: "count",
            headerName: "Hits",
          },
          {
            field: "last_reported",
            headerName: "Last Reported",
            view_type: "time",
          },
        ]}
        getDetailsText={(obj) => `<pre>${obj["stack"]}</pre>`}
        buildSearchParams={(x) => ({ title: "regex:" + x })}
      />
    </DBasePage>
  );
}
