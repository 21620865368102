import EditIcon from "@mui/icons-material/Edit";
import HomeIcon from "@mui/icons-material/Home";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Avatar,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { dassert, dcolors, dnetwork, TObject } from "corexxx";
import React, { CSSProperties, useState } from "react";
import { DWebTS } from "../common_ts/DWebTS";
import {
  DAlternativeScreen,
  DAlternativeScreenHolder,
} from "./DAlternativeScreen";
import { useDAppCommand } from "./DAppCommand";
import { DAvatarWithChangePicture } from "./DAvatarWithChangePicture";
import { DButton } from "./DButton";
import { DCol } from "./DCol";
import { DFormDialog } from "./DForm";
import { DGoogleLoginButton } from "./DGoogleLoginButton";
import { DListOfTitleSubtitleButton } from "./DListOfTitleSubTitleButton";
import { TNavigationItem } from "./DPageRootLayout";
import { DRow } from "./DRow";
import { DSeparator } from "./DSeparator";
import { DSpace } from "./DSpace";
import { DText } from "./DText";
import { DTextOr } from "./DTextOr";
import { corexxx } from "../../copy";
import { CommonTS } from "../common_ts/corexxx-ext";
import { useSearchParams } from "react-router-dom";
import { DTextWithControl } from "./DTextWithControl";
export const AccountMenu = ({ style }: { style?: CSSProperties }) => {
  let appCommand = useDAppCommand();
  return appCommand.accountState ? (
    <DRow style={{ flex: 0 }}>
      <Button>
        <Avatar
          sx={{ width: 32, height: 32 }}
          alt={appCommand.accountState.name}
          src={appCommand.accountState.image}
          onClick={() => {
            appCommand.api.navigate("/account");
          }}
        />
      </Button>
    </DRow>
  ) : (
    <Button
      style={{ color: "white" }}
      onClick={() => {
        appCommand.api.navigate("/account");
      }}
    >
      Join now
    </Button>
  );
};

export function AccountScreen({
  title,
  config,
}: {
  title?: string;
  config?: {
    style?: CSSProperties;
    extra_ele?: JSX.Element;
    extra_link?: TNavigationItem[];
  };
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParam = (param: any) => {
    // Remove the parameter from the URL
    searchParams.delete(param);
    setSearchParams(searchParams); // This updates the URL without reloading the page
  };

  let appCommand = useDAppCommand();
  const [activeScreen, setActiveScreen] = useState<
    | "signin"
    | "signup"
    | "forgetpassword"
    | "user_info"
    | "login_sessions"
    | "login_with_magic_link"
  >(appCommand.accountState?.auth_token_ ? "user_info" : "signin");

  let [username, set_username] = React.useState("");
  let [allSessions, setAllSession] = React.useState<TObject[]>([]);
  let [token, set_token] = React.useState("");
  let [name, set_name] = React.useState("");
  let [email, set_email] = React.useState("");
  let [password, set_password] = React.useState("");
  let [re_password, set_re_password] = React.useState("");
  let [error, set_error] = React.useState("");
  let [success, set_success] = React.useState("");
  let [loading, set_loading] = React.useState(false);
  let [step, set_step] = React.useState(1);
  function clear() {
    set_email("");
    set_name("");
    set_password("");
    set_username("");
    set_error("");
    set_success("");
    set_token("");
  }
  React.useEffect(() => {
    clear();
    // If we have a magic link try.
    if (searchParams.get("magic_link_token")) {
      (async () => {
        let magic_link_token = searchParams.get("magic_link_token");
        set_loading(true);
        set_error("Trying Magic link...");
        try {
          let res = await dnetwork.postSimpleStore(
            appCommand.state.simpleStoreEndpoint +
            "/api/simple_auth/login_with_magic_link",
            {
              magic_link_token: magic_link_token,
            },
          );
          clear();
          appCommand.setUserLogin(DWebTS.decode(res.out));
          set_success("Able to login with Magic link...");
        } catch (e: any) {
          set_error(e.message);
        }
        removeQueryParam("magic_link_token");
        set_loading(false);
      })();
    }
  }, []);

  React.useEffect(() => {
    if (appCommand.accountState) {
      setActiveScreen("user_info");
    } else {
      setActiveScreen("signin");
    }
  }, [appCommand.accountState]);

  return (
    <DCol style={{ maxWidth: 420, minHeight: 480, ...config?.style }}>
      {title ? (
        <>
          <Typography
            style={{
              fontWeight: "bold",
              color: dcolors.pallets.blue600,
              fontSize: 25,
              textAlign: "center",
              textTransform: "uppercase",
              paddingBottom: 10,
            }}
          >
            {title}
          </Typography>
          <DSeparator style={{ marginBottom: 20 }} />
        </>
      ) : null}

      <DCol style={{ padding: 0, width: "100%", boxSizing: "border-box" }}>
        {error.length > 0 ? (
          <Alert severity="error" style={{ marginBottom: 10 }}>
            {error}
          </Alert>
        ) : null}
        {success.length > 0 ? (
          <Alert severity="success" style={{ marginBottom: 10 }}>
            {success}
          </Alert>
        ) : null}
        <DAlternativeScreenHolder activescreen={activeScreen}>
          <DAlternativeScreen name="forgetpassword">
            <DCol style={{ display: step == 1 ? "flex" : "none" }}>
              <DText style={{ marginBottom: 20 }}>
                Step 1: Enter your username and get Token via Mail
              </DText>
              <TextField
                id="outlined-basic"
                label="Enter Username"
                variant="outlined"
                style={{}}
                value={username}
                onChange={(e) => set_username(e.target.value)}
              />
              <LoadingButton
                loading={loading}
                variant="contained"
                style={{ width: "100%", marginTop: 24 }}
                onClick={async () => {
                  set_loading(true);
                  set_error("");
                  try {
                    let res = await dnetwork.postSimpleStore(
                      appCommand.state.simpleStoreEndpoint +
                      "/api/simple_auth/forgot_password",
                      {
                        username: username,
                      },
                    );
                    set_success(res.msg);
                    set_step(2);
                  } catch (e: any) {
                    set_error(e.message);
                  }
                  set_loading(false);
                }}
              >
                Send Token via mail
              </LoadingButton>
            </DCol>
            <DCol style={{ display: step == 2 ? "flex" : "none" }}>
              <DText style={{ marginBottom: 0, marginTop: 20 }}>
                Step 2: Enter the token and new password
              </DText>
              <TextField
                id="outlined-basic"
                label="Token"
                variant="outlined"
                style={{ marginTop: 24 }}
                type="text"
                value={token}
                onChange={(e) => set_token(e.target.value)}
              ></TextField>
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                style={{ marginTop: 24 }}
                type="password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              ></TextField>
              <LoadingButton
                loading={loading}
                variant="contained"
                style={{ width: "100%", marginTop: 24 }}
                onClick={async () => {
                  set_loading(true);
                  clear();
                  try {
                    let res = await dnetwork.postSimpleStore(
                      appCommand.state.simpleStoreEndpoint +
                      "/api/simple_auth/reset_password",
                      {
                        username: username,
                        short_lived_token: token,
                        password_: password,
                      },
                    );
                    clear();
                    set_success(res.msg);
                    setActiveScreen("signin");
                    set_step(1);
                  } catch (e: any) {
                    set_error(e.message);
                  }
                  set_loading(false);
                }}
              >
                Reset password
              </LoadingButton>
            </DCol>
          </DAlternativeScreen>
          <DAlternativeScreen name="login_with_magic_link">
            <DText style={{ marginBottom: 20, fontSize: 20 }}>
              LogIn With Magic Link
            </DText>
            <DText
              style={{
                marginBottom: 20,
                fontSize: 15,
                color: dcolors.pallets.grey500,
              }}
            >
              Please enter your email below, we will send you a magic link in
              your email.
            </DText>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              style={{}}
              value={email}
              onChange={(e) => set_email(e.target.value)}
            />
            <LoadingButton
              loading={loading}
              variant="contained"
              style={{ width: "100%", marginTop: 24 }}
              onClick={async () => {
                set_loading(true);
                set_error("");
                try {
                  let res = await dnetwork.postSimpleStore(
                    appCommand.state.simpleStoreEndpoint +
                    "/api/simple_auth/generate_magic_link",
                    {
                      email: email,
                      url: window.location.origin + "/account",
                      app_name: appCommand.state.appConfig?.app_name,
                    },
                  );
                  clear();
                  set_success(
                    "A Magic Link has generated and send in your mail box. Please check your email!. The link will expire in 5 min!",
                  );
                } catch (e) {
                  set_error(
                    "Not able to generate Magic link. May be you can login as a password!",
                  );
                }
                set_loading(false);
              }}
            >
              Send Magic link to my Email!
            </LoadingButton>
          </DAlternativeScreen>

          <DAlternativeScreen name="signin">
            <DText style={{ marginBottom: 20, fontSize: 20 }}>Sign In</DText>
            <DText
              style={{
                marginBottom: 20,
                fontSize: 15,
                color: dcolors.pallets.grey500,
              }}
            >
              Welcome to Unified Login! You can now access the any Grodok family
              of apps using the same credentials..
            </DText>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              style={{}}
              value={email}
              onChange={(e) => set_email(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              style={{ marginTop: 24 }}
              type="password"
              value={password}
              onChange={(e) => set_password(e.target.value)}
            ></TextField>
            <LoadingButton
              loading={loading}
              variant="contained"
              style={{ width: "100%", marginTop: 24 }}
              onClick={async () => {
                set_loading(true);
                set_error("");
                try {
                  let res = await dnetwork.postSimpleStore(
                    appCommand.state.simpleStoreEndpoint +
                    "/api/simple_auth/login_by_email",
                    {
                      email: email,
                      password_: password,
                      app_name: appCommand.state.appConfig?.app_name,
                    },
                  );
                  appCommand.setUserLogin(DWebTS.decode(res.out));
                  clear();
                } catch (e) {
                  set_error("Not able to login");
                }
                set_loading(false);
              }}
            >
              Sign In
            </LoadingButton>
            <DTextOr />
            <Button
              variant="contained"
              style={{
                marginTop: 0,
                textTransform: "none",
                background: dcolors.pallets.blue800,
              }}
              onClick={() => {
                setActiveScreen("login_with_magic_link");
                clear();
                DWebTS.danalytics.report_action("forgetpassword_btn_clicked");
              }}
            >
              Login Without Password with Magic Link
            </Button>
            <Button
              style={{ marginTop: 20, textTransform: "none" }}
              onClick={() => {
                setActiveScreen("signup");
                clear();
                DWebTS.danalytics.report_action("signup_btn_clicked");
              }}
            >
              No account yet? Register now
            </Button>

            <Button
              style={{ marginTop: 0, textTransform: "none" }}
              onClick={() => {
                setActiveScreen("forgetpassword");
                clear();
                DWebTS.danalytics.report_action("forgetpassword_btn_clicked");
              }}
            >
              Forget password? Reset now.
            </Button>
            {appCommand.getAppConfig()?.google_client_id ? (
              <>
                <DRow style={{ marginTop: 5, marginBottom: 5 }}>
                  <DSpace
                    style={{ border: "1px solid #f1f1f1", marginRight: 10 }}
                  />
                  <DText>OR</DText>
                  <DSpace
                    style={{ border: "1px solid #f1f1f1", marginRight: 10 }}
                  />
                </DRow>
                <DGoogleLoginButton
                  google_client_id={
                    appCommand.getAppConfig()?.google_client_id!!
                  }
                />
              </>
            ) : null}
          </DAlternativeScreen>

          <DAlternativeScreen name="signup">
            <>
              <DText style={{ marginBottom: 20, fontSize: 20 }}>
                Create new account
              </DText>
              <DText
                style={{
                  marginBottom: 20,
                  fontSize: 15,
                  color: dcolors.pallets.grey500,
                }}
              >
                Welcome to Unified Login! You can now access the entire Grodok
                family of apps using the same credentials.
              </DText>
              <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                style={{ marginBottom: 12 }}
                value={name}
                onChange={(e) => set_name(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                style={{ marginBottom: 12 }}
                value={email}
                onChange={(e) => set_email(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Password"
                variant="outlined"
                style={{ marginTop: 12 }}
                type="password"
                value={password}
                onChange={(e) => set_password(e.target.value)}
              ></TextField>
              <TextField
                id="outlined-basic"
                label="Re-Enter Password"
                variant="outlined"
                style={{ marginTop: 12 }}
                type="password"
                value={re_password}
                onChange={(e) => {
                  set_re_password(e.target.value);
                }}
              ></TextField>
              {password != re_password && re_password.length > 0 ? (
                <DText
                  style={{
                    marginTop: 10,
                    fontSize: 10,
                    fontStyle: "italic",
                    color: dcolors.pallets.red600,
                  }}
                >
                  {" "}
                  Password not matched
                </DText>
              ) : null}
              <LoadingButton
                loading={loading}
                variant="contained"
                style={{ width: "100%", marginTop: 24 }}
                onClick={async () => {
                  set_loading(true);
                  set_error("");
                  try {
                    dassert.verifyNotNullAndEmpty(name, "Missing name");
                    dassert.verifyNotNullAndEmpty(name, "Missing email");
                    dassert.verifyNotNullAndEmpty(name, "Missing password");
                    await dnetwork.postSimpleStore(
                      appCommand.state.simpleStoreEndpoint +
                      "/api/simple_auth/register",
                      {
                        name: name,
                        email: email,
                        username: username,
                        password_: password,
                      },
                    );
                    clear();
                    set_success(
                      "registered Successfully. Now please login to continue",
                    );
                    setActiveScreen("signin");
                  } catch (e: any) {
                    set_error(`Not able to register. ${e.message}`);
                  }
                  set_loading(false);
                  DWebTS.danalytics.report_action("register_btn_clicked");
                }}
              >
                Register
              </LoadingButton>
              <Button
                style={{ marginTop: 20, textTransform: "none" }}
                onClick={() => {
                  setActiveScreen("signin");
                  clear();
                  DWebTS.danalytics.report_action("signin_btn_clicked");
                }}
              >
                Already having a account? login.
              </Button>
            </>
          </DAlternativeScreen>

          <DAlternativeScreen name="user_info">
            <DCol style={{ alignItems: "center", flex: 0 }}>
              <DRow
                style={{
                  width: "100%",
                  marginBottom: 30,
                  position: "relative",
                }}
              >
                <IconButton
                  href="/"
                  style={{ marginLeft: -10, marginTop: -10 }}
                >
                  <HomeIcon />
                </IconButton>
                <DSpace />
                <DFormDialog
                  prefill={appCommand.accountState}
                  model={{
                    data: [
                      { type: "input", name: "name" },
                      { type: "number", name: "phone" },
                      { type: "input", name: "username", disabled: true },
                      { type: "input", name: "email", disabled: true },
                      {
                        type: "select",
                        name: "currency",
                        options_list: ["GBP", "INR", "USD"],
                      },
                    ],
                  }}
                  dialogConfig={{
                    dialog_title: "Update your account",
                    triggerIcon: <EditIcon />,
                    triggerStyle: { marginRight: -10, marginTop: -10 },
                  }}
                  onSubmit={async (obj) => {
                    try {
                      await appCommand.updateAccount(obj);
                      set_success("profile updated");
                    } catch (e: any) {
                      set_error("Something went wrong+" + e.message);
                    }
                  }}
                />
              </DRow>
              <DAvatarWithChangePicture
                alt={appCommand.accountState?.name}
                src={appCommand.accountState?.image}
                sx={{ width: 80, height: 80 }}
                onChange={async (file) => {
                  try {
                    const path = await DWebTS.uploadAFile(file, {
                      file_name:
                        appCommand.accountState?._id + "profile_pic.png",
                      user_id: appCommand.accountState?.username || "",
                    });
                    await appCommand.updateAccount({ image: path });
                    set_success("Picture updated");
                  } catch (e: any) {
                    set_error("Something went wrong" + e.message);
                  }
                }}
              />

              <DText
                style={{
                  fontWeight: "bold",
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {appCommand?.accountState?.name || "Unknown"}(
                {appCommand?.accountState?.username || ""})
              </DText>
              {appCommand?.accountState?.email ? (
                <DText style={{ marginBottom: 0 }}>
                  {appCommand?.accountState?.email}
                </DText>
              ) : null}
              {appCommand?.accountState?.phone ? (
                <DText style={{ marginBottom: 20 }}>
                  Phone:{appCommand?.accountState?.phone}
                </DText>
              ) : null}
            </DCol>
            <DSpace />
            {config?.extra_ele}
            <DCol style={{ flex: 0 }}>
              {config?.extra_link?.map((y, idy) => (
                <Button
                  key={idy}
                  variant="outlined"
                  href={y.href}
                  style={{ marginBottom: 10 }}
                >
                  {y.text}
                </Button>
              ))}

              <Button
                variant="contained"
                style={{ background: dcolors.pallets.red500, marginBottom: 10 }}
                onClick={() => {
                  appCommand.logout();
                  clear();
                  DWebTS.danalytics.report_action("logout_btn_clicked");
                }}
              >
                Logout
              </Button>
              <Button
                style={{ textTransform: "lowercase", fontSize: 12 }}
                onClick={async () => {
                  appCommand.api.safeCall(async () => {
                    setAllSession(
                      DWebTS.decode(
                        (
                          await appCommand.api.getSimpleStore(
                            appCommand.state.simpleStoreEndpoint +
                            "/api/simple_auth/get_all_active_session",
                          )
                        ).out,
                      ),
                    );
                    setActiveScreen("login_sessions");
                  });
                }}
              >
                Review all active session?
              </Button>
            </DCol>
          </DAlternativeScreen>
          <DAlternativeScreen name="login_sessions">
            <DTextWithControl
              backBtnCallback={() => {
                setActiveScreen("user_info");
              }}
              text="All Active Sesssion"
            />
            <DListOfTitleSubtitleButton
              data={allSessions.map((x) => ({
                title: `${x.auth_token_ == appCommand.state.auth_token_ ? "**[current session]" : ""} ${x.app_name}, IP: ${x.endpoint?.remote_ip}, ${x.endpoint?.host}`,
                subtitle: `${x.endpoint?.user_agent}`,
                extra: x,
              }))}
              btn={{
                text: "Logout",
                onClick: async (x) => {
                  appCommand.api.safeCall(
                    async () => {
                      await dnetwork.getSimpleStore(
                        appCommand.state.simpleStoreEndpoint +
                        `/api/simple_auth/logout?auth_token_=${x.auth_token_}&username=${appCommand.accountState?.username}`,
                      );
                      setAllSession(
                        DWebTS.decode(
                          (
                            await appCommand.api.getSimpleStore(
                              appCommand.state.simpleStoreEndpoint +
                              "/api/simple_auth/get_all_active_session",
                            )
                          ).out,
                        ),
                      );
                    },
                    "Logout done",
                    "Failed to logout",
                  );
                },
              }}
            />
            <DButton
              text="Logout All"
              onClick={async () => {
                appCommand.api.safeCall(
                  async () => {
                    await dnetwork.getSimpleStore(
                      appCommand.state.simpleStoreEndpoint +
                      `/api/simple_auth/logout_all?auth_token_=${appCommand.accountState?.auth_token_}&username=${appCommand.accountState?.username}`,
                    );
                    await appCommand.logout();
                  },
                  "Logout all done",
                  "Failed to logout all",
                );
              }}
              style={{ marginTop: 10 }}
            />
          </DAlternativeScreen>
        </DAlternativeScreenHolder>
        <DText style={{ textAlign: "center", marginTop: 30, fontSize: 12 }}>
          Powered by grodok.com. T&C Apply
        </DText>
      </DCol>
    </DCol>
  );
}
