import { Alert } from "@mui/material";
import { dcolors, dtime, TObject } from "corexxx";
import { useState } from "react";
import { DHook } from "@libs/common_hooks/DHook";
import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DCol } from "@libs/dweb/DCol";
import { DashboardTiles } from "@libs/dweb/DDashboard";
import { DTable } from "@libs/dweb/DTable";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function ReliabilityMatrix() {
  let admin = useAdmin();
  let appCommand = useDAppCommand();
  let [data, setData] = useState<TObject[]>([]);
  let infoHook = DHook.useNetwork(
    appCommand.state.simpleStoreEndpoint + "/reliability/",
    { isSimpleStoreEndpoint: false },
  );

  return (
    <DBasePage>
      {infoHook.state.data ? (
        <DCol>
          <Alert severity="success">Simplestore is running as expected</Alert>
          <DashboardTiles
            data={[
              {
                title: "When Run",
                value: dtime.getAgoString(infoHook.state.data.out.run_time),
                color: dcolors.pallets.blue600,
                valueStyle: { fontSize: 24 },
              },
              {
                title: "Total Test Case",
                value: infoHook.state.data.out.test_result.length,
                color: dcolors.pallets.blue600,
              },
              {
                title: "Pass Count",
                value: infoHook.state.data.out.test_result.filter(
                  (x: any) => x.test_outcome == "pass",
                ).length,
                color: dcolors.pallets.green600,
              },
              {
                title: "Fail Count",
                value: infoHook.state.data.out.test_result.filter(
                  (x: any) => x.test_outcome == "fail",
                ).length,
                color: dcolors.pallets.red600,
              },
              {
                title: "Prep Count",
                value: infoHook.state.data.out.test_result.filter(
                  (x: any) => x.test_outcome == "prep",
                ).length,
                color: dcolors.pallets.yellow900,
              },
              {
                title: "Reliability %",
                value: (
                  ((infoHook.state.data.out.test_result.length -
                    infoHook.state.data.out.test_result.filter(
                      (x: any) => x.test_outcome == "fail",
                    ).length) /
                    infoHook.state.data.out.test_result.length) *
                  100
                ).toFixed(2),
                errorColor: false,
              },
            ]}
          />
          <DTable
            style={{ marginTop: 30 }}
            data={infoHook.state.data.out.test_result}
            columns={[
              {
                field: "Test Result",
                formatEx(arg, obj) {
                  return obj.test_outcome == "pass" ? (
                    <span>✅︎</span>
                  ) : (
                    <span>❌</span>
                  );
                },
              },
              {
                field: "line",
                headerName: "Line Ref",
              },
              {
                field: "line",
                headerName: "Command",
                format(arg, obj) {
                  return obj.command + "=>" + obj.arguments[0].slice(0, 200);
                },
              },
              {
                field: "Outcome",
                formatEx(arg, obj) {
                  return obj.test_outcome;
                },
                hover_text: (obj) => "Hello",
                sortFn: (a, b) => (a.test_outcome > b.test_outcome ? 1 : -1),
              },
            ]}
            actions={[
              {
                text: "details",
                onClick: (obj) => {
                  appCommand.api.showInformationDialog(
                    "More about this",
                    JSON.stringify(obj),
                  );
                },
              },
            ]}
          />
        </DCol>
      ) : null}
    </DBasePage>
  );
}
