import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DBox } from "@libs/dweb/DBox";
import { DButton } from "@libs/dweb/DButton";
import { DCol } from "@libs/dweb/DCol";
import { DInputString } from "@libs/dweb/DInputString";
import { DRow } from "@libs/dweb/DRow";
import { DTabView } from "@libs/dweb/DTabView";
import { DTextJSON } from "@libs/dweb/DTextJSON";
import { DTutorial } from "@libs/dweb/DTutorial";
import { TObject } from "corexxx";
import React, { useState } from "react";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

function PermissionGroup() {
  const [info, setInfo] = React.useState<TObject>({});
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();
  const [output, setOutput] = useState<TObject>({})
  const [input, setInput] = useState('')
  const [pdata, setPData] = useState({ username: "", permission: "" });
  return (
    <DBox header={{ title: "Manage Permission Group" }}>
      <DRow>
        <DCol nogrow style={{ padding: 10 }}>
          <div>
            <DInputString
              value={pdata.username}
              onChange={(x) => setPData({ ...pdata, username: x })}
              label="username"
              style={{ margin: 10, flex: 1, width: '100%' }}
            />
            <DInputString
              value={pdata.permission}
              onChange={(x) => setPData({ ...pdata, permission: x })}
              label="permission"
              style={{ margin: 10, flex: 1, width: '100%' }}
            />
            <DButton
              style={{ margin: 10, }}
              text="Add Permission"
              onClick={() =>
                appCommand.api.safeCall(async () => {
                  let result = await appCommand.api.getSimpleStore(
                    `${appCommand.state.simpleStoreEndpoint}/api/simple_auth/add_permission?permission=${pdata.permission}&username=${pdata.username}`,
                  );
                })
              }
            />
            <DButton
              style={{ margin: 10, }}
              text="remove Permission"
              onClick={() =>
                appCommand.api.safeCall(async () => {
                  let result = await appCommand.api.getSimpleStore(
                    `${appCommand.state.simpleStoreEndpoint}/api/simple_auth/remove_permission?permission=${pdata.permission}&username=${pdata.username}`,
                  );
                })
              }
            />
          </div>
        </DCol>
        <DCol>
          <DTutorial
            helperText="💡 Adding Users to a Permission Group 💡"
            description="Permission groups allows users to access certain features of the application. This is a feature to have differetiate between the admin, users, partners etc. To add a user to a permission group, enter the username and the permission and click on Add Permission."
            dos={[
              "Only super user should able to add and remove permission.",
              "Permisson Group are case senstive. Make sure you are adding to right users to permission"
            ]}
            donts={[
              "Adding permission does't help to change the data privacy",
            ]}
          />
        </DCol>
      </DRow>
    </DBox >
  )
}


function SystemConfig() {
  const [info, setInfo] = React.useState<TObject>({});
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();
  const [output, setOutput] = useState<TObject>({})
  const [input, setInput] = useState('')
  const [pdata, setPData] = useState({ username: "", permission: "" });
  return (
    <DBox header={{ title: "System Config Operation" }}>
      <DRow>
        <DCol nogrow style={{ padding: 10 }}>
          <div>
            <DButton text="Get"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore('/api/utils/system_config/get', {})
                setOutput(res)

              }}
              style={{ flex: 1, margin: 10 }}></DButton>
            <DButton text="Set"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore('/api/utils/system_config/set', {
                })
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
            <DButton text="Delete"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore('/api/utils/system_config/delete', {
                })
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
            <DButton text="Copy Schwab Token to System Config"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore('/api/utils/system_config/copy_schwab_oauth_to_system_config', {
                })
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
          </div>
          <DCol>
            <DTextJSON obj={output}></DTextJSON>
          </DCol>
        </DCol>
        <DCol>
          <DTutorial
            helperText="💡 Managing system Config 💡"
            description="System Config allows to manage the system wide configuration. This is a set of Key vlaue pair whcih is store in the /etc/systmeconfig file in the server to make a persistnace stotore"
            dos={[
              "Only super user should able to add and remove permission.",
              "Permisson Group are case senstive. Make sure you are adding to right users to permission"
            ]}
            donts={[
              "Adding permission does't help to change the data privacy",
            ]}
          />
        </DCol>
      </DRow>
    </DBox >
  )
}

export function ManageDataPrivacy() {
  let appCommand = useDAppCommand();
  const [output, setOutput] = useState<TObject>({})
  const [tablename, setTablename] = useState('')
  const [entryId, setEntryId] = useState('')
  const [userId, SetuserId] = useState('')

  return (
    <DBox header={{ title: "Manage Data Privacy" }}>
      <DRow>
        <DCol nogrow style={{ padding: 10 }}>
          <div>

            <DInputString
              value={tablename}
              onChange={setTablename}
              label="Secure Table Name"
              style={{ margin: 10, flex: 1, width: '100%' }}
            />
            <DInputString
              value={entryId}
              onChange={setEntryId}
              label="Entry Id"
              style={{ margin: 10, flex: 1, width: '100%' }}
            />
            <DInputString
              value={userId}
              onChange={SetuserId}
              label="User name"
              style={{ margin: 10, flex: 1, width: '100%' }} />

            <DButton
              text="Add Visibility"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore(`/api/${tablename}/visibility/add?username=${userId}&_id=${entryId}`, {})
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }} />
            <DButton text="Remove Visibility"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore(`/api/${tablename}/visibility/remove?username=${userId}&_id=${entryId}`, {})
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
            <DButton text="Get Information"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore(`/api/${tablename}?_project=_id,_visibility&_id=${entryId}`, {})
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
            <DButton text="Get All Ids"
              onClick={async () => {
                let res = await appCommand.api.getSimpleStore(`/api/${tablename}?_project=_id,_visibility`, {})
                setOutput(res)
              }}
              style={{ flex: 1, margin: 10 }}></DButton>
          </div>
          <DCol>
            <DTextJSON obj={output}></DTextJSON>
          </DCol>
        </DCol>
        <DCol>
          <DTutorial
            helperText="💡 Managing data privacy 💡"
            description="SimpleStore Natively support secure table. This allows to restrictions on shareing data across multiple users which is getting store on the same table. When a user create a entry in a secure table, the entry was tagged a owned by that user and other users can't see the entry. However, that owner can allows other users to see this information by adding visibility for that entry."
            dos={[
              "Only owner can share the information.",
              "You can add or remove visbility to the entry"
            ]}
            donts={[
              "Make sure you know the person you are sharing the information. This could be a security risk.",
            ]}
          />
        </DCol>
      </DRow>
    </DBox >
  )
}



export function AdminToolsPage() {
  const [info, setInfo] = React.useState<TObject>({});
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();
  const [output, setOutput] = useState<TObject>({})
  const [input, setInput] = useState('')
  const [pdata, setPData] = useState({ username: "", permission: "" });
  return (
    <DBasePage>
      <DTabView config={{
        tabview: "chip"
      }} data={[
        {
          text: 'Permission Group',
          view: <PermissionGroup />
        },
        {
          text: 'System Config',
          view: <SystemConfig />
        },
        {
          text: 'Data Privacy',
          view: <ManageDataPrivacy />
        },
      ]} />
    </DBasePage >
  );
}
