import BugReportIcon from "@mui/icons-material/BugReport";
import { TNestedNavigation } from "../../dweb/DPageRootLayout";
import { ComponentTestScreen } from "./ComponentTestScreen";
import { TestScreen } from "./TestScreen";
export const DrawerNestedNavigationDebugOnly: TNestedNavigation = [
  {
    title: "Debug",
    icon: <BugReportIcon />,
    child: [
      {
        title: "Test Component",
        screen: <ComponentTestScreen />,
        icon: <BugReportIcon />,
      },
      {
        title: "TestScreen",
        screen: <TestScreen />,
        icon: <BugReportIcon />,
      },
    ],
  },
];
