import { CSSProperties } from "react";
export const DRow = ({
  children,
  style,
  center,
}: {
  children?: any;
  style?: CSSProperties;
  center?: boolean;
}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: center ? "center" : "initial",
        flexWrap: "wrap",
        ...style,
      }}
    >
      {children || null}
    </div>
  );
};
