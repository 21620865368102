import { DTabView } from "@libs/dweb/DTabView";
import { DText } from "@libs/dweb/DText";
import { UploadCareFileUpload } from "@libs/dweb/UploadCareFileUpload";
import React from "react";
import { DBasePage } from "../App";

export function AssetsPage() {
  const [menu, setMenu] = React.useState<string>("UploadCare");
  return (
    <DBasePage>
      <DTabView config={{
        tabview: "chip",
      }} data={[
        {
          text: "UploadCare",
          view: <UploadCareFileUpload publicKey="847028a692abd6318a30" />,
        }, {
          text: "Others",
          view: <DText>Comming soon</DText>
        }
      ]} />
    </DBasePage>
  );
}
