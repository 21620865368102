import { Alert } from "@mui/material";
import { TObject, dlog, dnetwork } from "corexxx";
import jwt_decode from "jwt-decode";
import { useState } from "react";
import { DWebTS } from "../common_ts/DWebTS";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";

export const DGoogleLoginButton = ({
  google_client_id,
}: {
  google_client_id: string;
}) => {
  const [error, setError] = useState("");
  const appCommand = useDAppCommand();

  DWebTS.useEffectAsync(async () => {
    const callback = async (response: any) => {
      try {
        const data: TObject = jwt_decode(response.credential);
        //dlog.obj(data);
        let res = await dnetwork.postSimpleStore(
          appCommand.state.simpleStoreEndpoint +
          "/api/simple_auth/google_login",
          data,
        );
        appCommand.setUserLogin(DWebTS.decode(res.out));
      } catch (e: any) {
        setError("Login Failed. " + e.message);
      }
    };
    try {
      await DWebTS.loadScript("https://accounts.google.com/gsi/client");
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: google_client_id,
        callback: callback,
      });
      // @ts-ignore
      google.accounts.id.renderButton(
        document.getElementById("google_login_btn") as HTMLElement,
        { theme: "outline", size: "medium" },
      );
      //google.accounts.id.prompt();
    } catch (e) {
      throw e;
    }
  }, []);
  return (
    <DCol style={{ justifyContent: "center", alignItems: "center" }}>
      {error.length > 0 ? (
        <Alert style={{ marginBottom: 10 }} color="error">
          {error}
        </Alert>
      ) : null}
      <div id="google_login_btn" />
    </DCol>
  );
};
