import { CSSProperties } from "react";
export const DSeparator = ({ style }: { style?: CSSProperties }) => (
  <div
    style={{
      width: "100%",
      height: 1,
      borderTop: "1px solid #f1f1f1",
      ...style,
    }}
  />
);
