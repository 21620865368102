import { TextField } from "@mui/material";
import { TStringCallback } from "corexxx";
import { CSSProperties } from "react";

export function DTextAreaInput({
  label,
  value,
  onChange,
  style,
}: {
  label: string;
  value: string;
  onChange: TStringCallback;
  style?: CSSProperties;
}) {
  return (
    <TextField
      multiline rows={4} variant="outlined" fullWidth
      size="small"
      style={{ ...style }}
      placeholder={label}
      label={label}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
