import { RefreshOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton, } from "@mui/material";
import { dcolors, TVoidCalBack } from "corexxx";
import { CSSProperties, useState } from "react";
import { DButton } from "./DButton";

export function DRefreshButton({
  onClick,
  style,
  dark,
  btn
}: {
  onClick: TVoidCalBack;
  style?: CSSProperties;
  dark?: boolean;
  btn?: boolean;
}) {
  let [loading, setLoading] = useState(false);

  if (btn) {
    return (
      <DButton
        style={{ color: "white" }}
        onClick={async () => {
          setLoading(true);
          try {
            await onClick();
          } catch (e) { }
          setLoading(false);
        }}
      >

        {loading ? <CircularProgress size={10} /> : <span> Refresh <span style={{ fontSize: 20 }}>⟳ </span> </span>}
      </DButton>
    );
  }

  return (
    <IconButton
      style={{ ...style, color: dark ? "white" : dcolors.pallets.blue700 }}
      onClick={async () => {
        setLoading(true);
        try {
          await onClick();
        } catch (e) { }
        setLoading(false);
      }}
    >
      {loading ? <CircularProgress size={20} /> : <RefreshOutlined />}
    </IconButton>
  );
}
