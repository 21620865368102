import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { BlobOptions } from "buffer";
import { TStringCallback } from "corexxx";
import { CSSProperties } from "react";

export type TOptions = string[] | { text?: string, value: string, tooltip?: string }[]

export function convertToTextValue(options: TOptions): { text: string; value: string, tooltip?: string }[] {
  if (Array.isArray(options)) {
    // Case 1: options is an array of strings
    if (typeof options[0] === "string") {
      return options.map((option) => ({
        text: option as string,
        value: option as string,
      }));
    }

    // Case 2: options is an array of objects with optional text and value properties
    return (options as { text?: string; value: string, tooltip?: string }[]).map((option) => ({
      text: option.text || option.value,
      value: option.value,
      tooltip: option.tooltip
    }));
  }

  // Return an empty array if the input is not in the expected format
  return [];
}

export const DRadioButton = ({
  style,
  value,
  onChange,
  options,
  label,
  dark,
  row
}: {
  dark?: boolean;
  style?: CSSProperties;
  options: TOptions;
  value: string;
  onChange: (str: string, idx: number) => void;
  label?: string;
  row?: boolean
}) => {
  return (
    <FormControl component="fieldset" style={{ marginBottom: 10, ...style }}>
      {label ? (
        <FormLabel
          component="legend"
          style={{ color: dark ? "white" : "black" }}
        >
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup
        aria-label="options"
        name="options"
        value={value}
        row={row}
        onChange={(event) => {
          const value = event.target.value;
          let _idx = -1;
          convertToTextValue(options).forEach((x, idx) => {
            if (x.value == value) {
              _idx = idx;
            }
          })
          onChange(value, _idx);
        }
        }
      >
        {convertToTextValue(options).map((x, idx) => (
          <Tooltip title={x.tooltip || ''}>
            <FormControlLabel
              key={idx}
              style={{
                textTransform: "capitalize",
                color: dark ? "white" : "black",
              }}
              value={x.value}
              control={<Radio />}
              label={x.text.replaceAll("_", " ") + ""}
            />
          </Tooltip>
        ))}
      </RadioGroup>
    </FormControl>
  );
};


