import { DHook } from "@libs/common_hooks/DHook";
import { useDAppCommand } from "@libs/dweb/DAppCommand";
import { DButton } from "@libs/dweb/DButton";
import { DCol } from "@libs/dweb/DCol";
import DHozSplitView from "@libs/dweb/DHozSplitView";
import { DInputString } from "@libs/dweb/DInputString";
import { DTextAreaInput } from "@libs/dweb/DInputTextArea";
import { DRadioButton } from "@libs/dweb/DRadioButton";
import { DRow } from "@libs/dweb/DRow";
import { DSpace } from "@libs/dweb/DSpace";
import { DSwitchInput } from "@libs/dweb/DSwitchInput";
import { DTabView } from "@libs/dweb/DTabView";
import { DText } from "@libs/dweb/DText";
import { DTextJSON } from "@libs/dweb/DTextJSON";
import { FormControl, FormLabel, TextField } from "@mui/material";
import { dnetwork } from "corexxx";
import React from "react";
import { DBasePage } from "../App";
import { useAdmin } from "../Hook";

export function ApiExplorerPage() {
  const [code, setCode] = React.useState("");
  let adminHook = useAdmin();
  let appCommand = useDAppCommand();

  let [type, setType] = React.useState('GET')
  let [authToken, setAuthToken] = React.useState(false)

  let [url, setUrl] = React.useState("/api")
  let [body, setBody] = React.useState('')
  let [result, setReslut] = React.useState({});

  DHook.useAsyncEffect(async () => {
    setCode(
      await appCommand.api.get(
        appCommand.state.simpleStoreEndpoint + "/admin/log",
      ),
    );
  }, [appCommand.state.simpleStoreEndpoint]);

  return (
    <DBasePage>
      <DHozSplitView style={{ height: 'calc(100vh - 200px)' }} topComponent={
        <DCol style={{ padding: 20 }}>
          <DRow center>
            <DInputString value={appCommand.state.simpleStoreEndpoint} onChange={() => { }} label="Base" style={{ width: 300 }} />
            <DInputString value={url} onChange={setUrl} label="API" style={{ width: 400, marginRight: 10 }} />
            <DSwitchInput value={authToken} onChange={setAuthToken} label="AuthToken?"></DSwitchInput>
            <DSpace />
            <DRadioButton row value={type} onChange={setType} options={['GET', 'POST']} />
            <DButton style={{ marginLeft: 20, height: 40 }} onClick={async () => {
              try {
                let out = {}
                if (type == 'GET') {
                  out = await dnetwork.getJson(appCommand.state.simpleStoreEndpoint.trim() + url + (authToken ? '&auth_token_=' + appCommand.state.auth_token_ : ''))
                }
                if (type == 'POST') {
                  out = await dnetwork.postJson(appCommand.state.simpleStoreEndpoint.trim() + url, { ...JSON.parse(body), auth_token_: appCommand.state.auth_token_ })
                }
                setReslut(out)
              } catch (e: any) {
                appCommand.api.setNotification({ type: 'error', msg: e.message })
              }
            }}>Submit</DButton>
          </DRow>
          <DTextAreaInput value={body} onChange={setBody} label="Body" />
          <DRow>
            <DSpace />

          </DRow>
        </DCol>
      } bottomComponent={< DCol style={{ padding: 10 }} >
        <DTabView
          config={{ tabview: 'chip' }}
          data={[
            {
              text: 'json', view: <DTextJSON obj={result} />,
            },
            {
              text: 'raw', view: <DText>{result + ''}</DText>
            }
          ]} />

      </DCol >} />
    </DBasePage >
  );
}




const MuiTextArea = () => {
  return (
    <FormControl fullWidth>
      <FormLabel>Enter text</FormLabel>
      <TextField multiline rows={4} variant="outlined" fullWidth />
    </FormControl>
  );
};