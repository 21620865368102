import { DWebTS } from '@libs/common_ts/DWebTS';
import { useDAppCommand } from '@libs/dweb/DAppCommand';
import { dlog, dnetwork } from 'corexxx';
import { createContext, useContext, useEffect, useState } from 'react';

// Create a context for sharing sessionId across components
type TData = { sessionId?: string, launchTracker: any };
const AnalyticsContext = createContext<TData>({ sessionId: undefined, launchTracker: () => { } });

// We have a bug where launchTracker is called 2 times, so we need to have only session init
var onlyOnce = false;

export const DAnalyticsProvider = ({ children }: { children: any }) => {
    const [sessionId, setSessionId] = useState("");
    let appComamnd = useDAppCommand();
    // Store the sessionId to avoid multiple requests
    const launchTracker = async () => {
        if (onlyOnce == true) {
            dlog.d('Session already exists, skipping launch...');
            return;
        }
        onlyOnce = true;
        dlog.d('[ONCE] Launching tracker started...');
        try {
            let res = await dnetwork.postSimpleStore(`${appComamnd.state.simpleStoreEndpoint}/api/analytics/launch`, {
                "app_id": appComamnd.state.appConfig?.app_id,
                "app_version": 0,
                "device_os": DWebTS.getDeviceOS(),
                "device_id": DWebTS.getDeviceID(),
                "device_api": DWebTS.getBrowserInfo().name
            });
            dlog.d('[ONCE] Launching tracker ended . Session', res.out.session);
            setSessionId(res.out.session); // Set the sessionId
        } catch (error) {
            console.error('Launch tracker failed:', error);
        }
    };

    // Launch tracker once at the start
    useEffect(() => {
        launchTracker();
    }, []);

    return (
        <AnalyticsContext.Provider value={{ sessionId, launchTracker }}>
            {children}
        </AnalyticsContext.Provider>
    );
};

// Custom hook for using the analytics context
export const useSimpleStoreAnalytics = () => {
    const { sessionId } = useContext<TData>(AnalyticsContext);
    let appCpmmand = useDAppCommand();

    const _trackAction = async (action_type: string, action_tag: string) => {
        if (!sessionId) {
            dlog.e('No sessionId available.');
            return;
        }
        try {
            const response = await dnetwork.postSimpleStore(`${useDAppCommand().state.simpleStoreEndpoint}/api/analytics/action`, {
                session: sessionId,
                app_id: appCpmmand.state.appConfig?.app_id,
                action: action_type,
                tag: action_tag,
            });

            if (!response.out) throw new Error('Failed to track action');

            dlog.d('Action tracked successfully:', response.out);
        } catch (error) {
            dlog.e('Action tracking failed:', error);
        }
    };
    return {
        trackAction: _trackAction,
        sessionId: sessionId,
    };
};
