import { CSSProperties } from "react";
export const DCol = ({
  children,
  style,
  id,
  className,
  nogrow
}: {
  children?: any;
  style?: CSSProperties;
  id?: string;
  className?: string;
  nogrow?: boolean;
}) => {
  return (
    <div
      style={{ display: "flex", flex: 1, flexDirection: "column", flexGrow: nogrow ? 'wrap' : 1, ...style }}
      id={id}
      className={className}
    >
      {children || null}
    </div>
  );
};
