import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { CSSProperties } from "react";
import { useDAppCommand } from "./DAppCommand";
import { DNestedMenu } from "./DNestedMenu";
import { TDPageRootExtraComponent, TDPageRootLayoutConfig } from "./DPageRootLayout";
import { DSpace } from "./DSpace";
import { DSwitchInput } from "./DSwitchInput";
import { DWebTS } from "../common_ts/DWebTS";

export const DNavigation = ({
  config,
  extraComponent,
  leftElement,
  leftOffset,
  onRequestPageChange,
}: {
  config: TDPageRootLayoutConfig;
  extraComponent?: TDPageRootExtraComponent;
  leftElement?: any;
  leftOffset?: number;
  onRequestPageChange?: (page: any) => void;
}) => {
  const appCommand = useDAppCommand();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let style: CSSProperties = {
    backgroundColor: appCommand.getAppConfig()?.primary_color!!,
    backgroundImage: appCommand.getAppConfig()?.primary_background,
    ...config.appBarStyle,
  };
  if (config.appBarTrasparent) {
    style = {
      background: "transparent",
      boxShadow: "none",
      color: "black",
      ...style,
    };
  }
  return (
    <AppBar
      style={{
        position: "fixed",
        left: leftOffset || 0,
        maxWidth: `calc( 100% - ${leftOffset || 0}px)`,
        ...style,
      }}
    >
      <Toolbar
        variant="dense"
        style={{ alignItems: "center", display: "flex" }}
      >
        {leftElement}
        {config.logo ? (
          <a href="/">
            <img
              src={config.logo}
              style={{ width: 23, height: 23, marginRight: 10 }}
            />
          </a>
        ) : null}
        {config.icon ? <a href="/">{config.icon}</a> : null}
        <Typography
          variant="h6"
          component="div"
          sx={{ ...config.appBarTextStyle, marginRight: 5 }}
        >
          <a
            href={config.title_path}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {config.title}
          </a>
        </Typography>

        {config.topNavigation
          ? config.topNavigation.map((x, idx) => {
            if (x.isActive == false) {
              return null;
            }
            if ((x.child?.length || 0) > 0) {
              return (
                <DNestedMenu
                  menu={x}
                  key={idx}
                  onClick={(jdx) => {
                    let screen = x.child!![jdx].screen;
                    if (screen) {
                      onRequestPageChange?.(screen);
                    }
                  }}
                />
              );
            }
            return (
              <Button
                href={x.path}
                key={idx}
                style={{ color: "white", textTransform: "none", ...x.style }}
                onClick={() => {
                  onRequestPageChange?.(x.screen);
                }}
              >
                {x.title}
              </Button>
            );
          })
          : null}
        {extraComponent?.navLeftJsx}
        <DSpace />
        {extraComponent?.navRightJsx}
        {config.rightActions?.map((x, idx) => {
          if (x.showAfterLogin && !appCommand.accountState) {
            return null;
          }
          return x.text ? (
            <Button
              onClick={x.onClick}
              key={idx}
              style={{ textTransform: "capitalize", ...config.appBarTextStyle }}
              href={x?.href}
            >
              {x.text}
            </Button>
          ) : (
            <IconButton
              key={idx}
              size="large"
              aria-label="search"
              color="inherit"
              onClick={x.onClick}
            >
              {x.icon}
            </IconButton>
          );
        })}
        {config.showMoneyPrivacy ? (
          <DSwitchInput
            style={{ color: "white" }}
            dark
            value={appCommand.state.textPrivacyState}
            onChange={(val) => {
              if (val == true) {
                appCommand.api.setTextPrivacyState(true)
              } else {
                appCommand.api.showPrompt("Enter Privacy passcode", async (code) => {
                  if (code == "0000") {
                    appCommand.api.setTextPrivacyState(false)
                  }
                });
              }
            }}
            label="Privacy"
          />
        ) : null}
        {appCommand.accountState ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {config.appBarConfig?.appBarRightJSX || null}
            <IconButton
              size="large"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
              href={config.settingNavList ? "" : "/account"}
              color="inherit"
            >
              <Avatar
                sx={{ width: 24, height: 24 }}
                alt={appCommand.accountState.name}
                src={appCommand.accountState.image}
              />
              {config.settingNavList ? (
                <KeyboardArrowDownIcon style={{ marginLeft: 10 }} />
              ) : null}
            </IconButton>
            {config.settingNavList ? (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null);
                }}
              >
                {config.settingNavList?.map((k, idk) => {
                  return (
                    <MenuItem
                      key={idk}
                      onClick={() => {
                        appCommand.api.navigate(k.link);
                      }}
                    >
                      {k.text}
                    </MenuItem>
                  );
                })}
              </Menu>
            ) : null}
          </div>
        ) : (
          <Button
            style={{ textTransform: "capitalize", ...config.appBarTextStyle }}
            href={"/account"}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};
