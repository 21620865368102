import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Drawer,
  IconButton,
} from "@mui/material";
import { TVoidCalBack, dcolors, dlog } from "corexxx";
import React, { CSSProperties, ReactNode, useState } from "react";
import { Route } from "react-router-dom";
import _ from "underscore";
import { DrawerNestedNavigationDebugOnly } from "../components/screens/Register";
import { useDAppCommand } from "./DAppCommand";
import { DCol } from "./DCol";
import { DNavigation } from "./DNavigation";
import { DResponsiveWindow } from "./DResponsiveWindow";
import { DRow } from "./DRow";
import { DSpace } from "./DSpace";
import { DText } from "./DText";

import MenuIcon from "@mui/icons-material/Menu";
import { DHook } from "../common_hooks/DHook";
import { DDot } from "./DDot";
import { DFooter } from "./DFooter";
import { DPageInConstruction } from "./DSamplePages";
import { DNeedsLogin } from "./DNeedsLogin";
export type TNavigationItem = {
  text: string;
  onClick?: TVoidCalBack;
  icon?: any;
  subtext?: string;
  href?: string;
  showAfterLogin?: boolean;
};

export type TNestedNavigation = Array<{
  title: string;
  path?: string;
  isActive?: boolean; // by default true
  style?: CSSProperties;
  screen?: JSX.Element;
  screenFn?: () => JSX.Element;
  icon?: JSX.Element;
  child?: {
    title: string;
    path?: string;
    screen?: JSX.Element;
    screenFn?: () => JSX.Element;
    icon?: JSX.Element;
  }[];
}>;

// Core App Layout and Navigation
export type TDPageRootLayoutConfig = {
  isDrawerEnabled?: boolean // this need to to be true to you want to render from drawer side. else this will not work
  title: string;
  title_path?: string;
  settingNavList?: { text: string; link: string }[];
  login?: boolean;
  drawerWidth?: number;
  logo?: any;
  icon?: any;
  rightActions?: TNavigationItem[]; // showing list of action in right size.
  appBarTrasparent?: boolean;
  appBarTextStyle?: CSSProperties;
  appBarStyle?: CSSProperties;
  hideFooter?: Boolean;
  topNavigation?: TNestedNavigation;
  appBarConfig?: {
    appBarRightJSX?: JSX.Element;
  };
  showMoneyPrivacy?: boolean; // making it true should show a toggle of privacy
};

// If anything change here, the app doesnt reload ( not moved to the hime screen)
export type TDPageRootExtraComponent = {
  navRightJsx?: any; // allow adding any items in right side of top nav
  navLeftJsx?: any; // allow adding any items in left side of top nav
}

export function getAllRoute(nav?: TNestedNavigation): JSX.Element {
  return (
    <>
      {nav?.map((x) => {
        return (
          <>
            {x.child?.map((y) => {
              dlog.d("registering path:" + x.path + y.path);
              if (y.screen) {
                return <Route path={"" + x.path + y.path} element={y.screen} />;
              } else {
                return (
                  <Route path={"" + x.path + y.path} element={y.screenFn?.()} />
                );
                //return <Route path={x.path + y.path}></Route>;
              }
            })}
          </>
        );
      })}
    </>
  );
}

// should not be used externally
/*export*/ const DAppParentWrapper = ({ children }: { children: any }) => {
  const appCommand = useDAppCommand();
  return (
    <div>
      {appCommand.isDebugMode() ? (
        <DText
          style={{
            position: "fixed",
            top: 0,
            width: "100%",
            color: "white",
            background: dcolors.pallets.red600,
            zIndex: 101,
            fontSize: 10,
            textAlign: "center",
          }}
        >
          You are running in debug mode
        </DText>
      ) : null}
      <div>{children}</div>
    </div>
  );
};

// You can use this **only when you needs a Top Navigation***. If you want to use Drawe use another compoennet
export const DPageRootLayout = ({
  children,
  style,
  config,
  leftElement,
  navLeftOffset,
  extraComponent,
}: {
  children: any;
  style?: CSSProperties;
  config: TDPageRootLayoutConfig;
  extraComponent?: TDPageRootExtraComponent;
  leftElement?: any;
  navLeftOffset?: number;
}) => {
  const [page, setPage] = useState(children);

  // This must be to done if you wants to drawer working...VVI
  React.useEffect(() => {
    dlog.d("setpagecalled")
    if (config.isDrawerEnabled) {
      dlog.d("Chnaging Rootpage view..")
      setPage(children);
    } else {
      dlog.d("ignore changing page as no drawer is enabled")
    }
  }, [children]);
  return (
    <DNeedsLogin>
      <DAppParentWrapper>
        <DCol style={{ flex: 1, position: "relative" }}>
          <DNavigation
            leftElement={leftElement}
            config={config}
            extraComponent={extraComponent}
            leftOffset={navLeftOffset}
            onRequestPageChange={setPage}
          />
          <DCol
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              ...style,
              paddingTop: 46,
              minHeight: "100vh",
            }}
          >
            <DCol style={{ position: "relative" }}>{page}</DCol>
            {!config.hideFooter ? <DFooter /> : null}
          </DCol>
        </DCol>
      </DAppParentWrapper>
    </DNeedsLogin>
  );
};

// Option 2: top and side navigation App Root with Top Navigation Bar + Drawer
export type TDrawerMenu = {
  name: string;
  onClick?: TVoidCalBack;
  screen?: JSX.Element;
  icon?: ReactNode;
  subMenuList?: {
    name: string;
    onClick?: TVoidCalBack;
    screen?: JSX.Element;
    icon?: ReactNode;
  }[];
}[];
export const DPageRootLayoutWithDrawer = React.memo(
  ({
    config,
    children,
    drawerContent,
    drawerOpen,
    drawerPreset,
    bodyStyle,
    extraComponent,
  }: {
    config: TDPageRootLayoutConfig;
    extraComponent?: TDPageRootExtraComponent;
    bodyStyle?: CSSProperties;
    children?: any;
    drawerContent?: any;
    drawerOpen?: boolean;
    drawerPreset?: {
      drawerWidth?: number;
      headerText?: String;
      headerExtra?: JSX.Element;
      footerExtra?: JSX.Element;
      footerText?: String;
      drawerNestedNavigation?: TNestedNavigation; // Depericated use MenuList
      onSettingClicked?: TVoidCalBack;
      drawerBackground?: string;
      color?: string;
      menuList?: TDrawerMenu;
    };
  }) => {
    dlog.d("DPageRootLayoutWithDrawer rerendered");
    const appCommand = useDAppCommand();
    const isTablet = false;
    let KdrawerWidth = isTablet
      ? window.innerWidth
      : drawerPreset?.drawerWidth || config?.drawerWidth || 350;
    const [open, setOpen] = React.useState(drawerOpen || false);
    const [holderNode, setHolderNode] = useState<JSX.Element | undefined>(
      drawerPreset?.menuList?.[0]?.screen || children,
    );
    const [drawerWidth, setDrawerWidth] = React.useState(0);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    const [selectedMenu, setSelectedMenu] = React.useState("");
    const [selectedSubMenu, setSelectedSubMenu] = React.useState("");
    const [drawerActiveIndex, setDrawerActiveIndex] = useState(0);

    // Getting rendered too many time
    DHook.useMountLogger("DPageRootLayoutWithDrawer");

    if (appCommand.isDebugMode()) {
      _.extend(
        drawerPreset?.drawerNestedNavigation,
        DrawerNestedNavigationDebugOnly,
      );
    }

    // TODO: THIS IS A Conclict
    // LITHUK needs this as as the child should be changing
    // TODO: This is causing issue while chnageing anything will effect navigation
    React.useEffect(() => {
      // This is chnanging the currently renderer menu abductbly
      //setHolderNode(drawerPreset?.menuList?.[0]?.screen || children)
    }, [children, drawerPreset]);

    React.useEffect(() => {
      setDrawerWidth(open ? KdrawerWidth : 0);
    }, [open]);
    const handleChange =
      (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };
    let drawerStyle: CSSProperties = {
      color: drawerPreset?.color || "black",
      background: drawerPreset?.drawerBackground || "white",
    };

    return (
      <DNeedsLogin>
        <DCol style={bodyStyle}>
          <DPageRootLayout
            config={{ ...config, isDrawerEnabled: true }}
            navLeftOffset={drawerWidth}
            leftElement={
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 1 }}
                onClick={() => setOpen(!open)}
              >
                {<MenuIcon style={{ ...config?.appBarTextStyle }} />}
              </IconButton>
            }
            style={{
              marginLeft: open ? drawerWidth : 0,
              width: open ? `calc( 100% - ${drawerWidth}px)` : "100%",
            }}
          >
            {holderNode || <DPageInConstruction />}
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
                ...drawerStyle,
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              {drawerContent}
              {drawerPreset ? (
                <DCol style={{ ...drawerStyle }}>
                  <div
                    style={{
                      backgroundColor:
                        appCommand.state.appConfig?.primary_color || "black",
                      padding: "15px 5px",
                      textAlign: "left",
                      color: "white",
                    }}
                  >
                    <DRow center>
                      <DText
                        mode="title"
                        style={{ textAlign: "left", color: "white", flex: 1 }}
                      >
                        {drawerPreset.headerText || "Welcome"}
                      </DText>
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setOpen(!open)}
                      >
                        {
                          <CloseIcon
                            style={{
                              color: "white",
                              ...config?.appBarTextStyle,
                            }}
                          />
                        }
                      </IconButton>
                    </DRow>
                    {drawerPreset.headerExtra}
                  </div>

                  {drawerPreset?.menuList
                    ? drawerPreset.menuList.map((x, idx) => {
                      if (!x.subMenuList || x.subMenuList.length == 0) {
                        return (
                          <Button
                            key={idx}
                            style={{
                              color: drawerStyle.color,
                              justifyContent: "left",
                              textTransform: "capitalize",
                              fontSize: 16,
                              paddingLeft: 20,
                              fontWeight:
                                selectedMenu == `${idx}` ? "bold" : "normal",
                              backgroundColor:
                                selectedMenu == `${idx}`
                                  ? dcolors.pallets.blue50
                                  : dcolors.pallets.transparent,
                            }}
                            onClick={() => {
                              setSelectedMenu(`${idx}`);
                              setHolderNode(x.screen);
                            }}
                            startIcon={x.icon}
                          >
                            <DRow center>
                              <DDot
                                size={10}
                                color={
                                  selectedMenu == `${idx}`
                                    ? dcolors.pallets.blue400
                                    : "#00000000"
                                }
                                style={{ marginRight: 5 }}
                              />
                              <DText>{x.name}</DText>
                            </DRow>
                          </Button>
                        );
                      }

                      return (
                        <Accordion
                          key={idx}
                          disableGutters={true}
                          expanded={selectedMenu == `${idx}`}
                          onChange={(e, expanded) => {
                            setSelectedMenu(expanded ? "" + idx : "-");
                          }}
                          style={{
                            background: "#00000000",
                            boxShadow: "none",
                            width: "100%",
                            border: 0,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon
                                style={{ color: drawerStyle.color }}
                              />
                            }
                            style={{
                              margin: 0,
                              padding: 0,
                              border: 0,
                              flex: 0,
                              width: "100%",
                            }}
                          >
                            <Button
                              key={idx}
                              style={{
                                justifyContent: "left",
                                textTransform: "capitalize",
                                fontSize: 16,
                                paddingLeft: 20,
                                color:
                                  selectedMenu == `${idx}`
                                    ? dcolors.pallets.grey700
                                    : dcolors.pallets.grey200,
                              }}
                              onClick={() => {
                                setSelectedMenu(`${idx}`);
                                if (x.screen) {
                                  setHolderNode(x.screen);
                                } else {
                                  setSelectedSubMenu(`${idx}.0`);
                                  // @ts-ignore
                                  setHolderNode(x.subMenuList[0].screen);
                                }
                              }}
                              startIcon={x.icon}
                            >
                              <DRow center>
                                <DText
                                  style={{
                                    color:
                                      selectedMenu == `${idx}`
                                        ? dcolors.pallets.black
                                        : dcolors.pallets.grey500,
                                  }}
                                >
                                  {x.name}
                                </DText>
                              </DRow>
                            </Button>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{ padding: 0, paddingLeft: 0 }}
                          >
                            <DCol>
                              {x.subMenuList?.map((y, idy) => {
                                return (
                                  <Button
                                    key={idy}
                                    style={{
                                      color: drawerStyle.color,
                                      justifyContent: "left",
                                      textTransform: "capitalize",
                                      marginLeft: 20,
                                      fontSize: 16,
                                      fontWeight:
                                        selectedSubMenu == `${idx}.${idy}`
                                          ? "bold"
                                          : "normal",
                                    }}
                                    startIcon={y.icon}
                                    onClick={() => {
                                      setSelectedSubMenu(`${idx}.${idy}`);
                                      setHolderNode(y.screen);
                                    }}
                                  >
                                    <DRow center>
                                      <DDot
                                        size={10}
                                        color={
                                          selectedSubMenu == `${idx}.${idy}`
                                            ? dcolors.pallets.blue400
                                            : "#00000000"
                                        }
                                        style={{ marginRight: 5 }}
                                      />
                                      <DText
                                        style={{
                                          color:
                                            selectedSubMenu == `${idx}.${idy}`
                                              ? "black"
                                              : "grey",
                                        }}
                                      >
                                        {y.name}
                                      </DText>
                                    </DRow>
                                  </Button>
                                );
                              })}
                            </DCol>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })
                    : null}

                  <DSpace />
                  <DCol
                    style={{
                      borderTop: "1px solid grey",
                      padding: 20,
                      flex: 0,
                      width: "100%",
                    }}
                  >
                    <DRow style={{ alignItems: "center", flex: 0 }}>
                      <DText style={{ flex: 1, color: drawerStyle.color }}>
                        © copyright grodok.com 2023
                      </DText>
                    </DRow>
                  </DCol>
                </DCol>
              ) : null}
            </Drawer>
          </DPageRootLayout>
        </DCol>
      </DNeedsLogin>
    );
  },
);
