import { CSSProperties } from "react";
import image_placeholder from "../img/image_placeholder.png";
export const DImage = ({
  src,
  style,
  href,
}: {
  href?: string;
  src?: string;
  style?: CSSProperties;
}) => {
  if (href) {
    return (
      <a href={href}>
        <img src={src || image_placeholder} style={style} />
      </a>
    );
  }
  return <img src={src || image_placeholder} style={style} />;
};
