import { CSSProperties } from "react";

export function DDot({
  color,
  size,
  style,
}: {
  style?: CSSProperties;
  color?: string;
  size?: number;
}) {
  return (
    <div
      style={{
        background: color,
        borderRadius: "50%",
        width: size || 5,
        height: size || 5,
        ...style,
      }}
    />
  );
}
